
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'

@Component
  export default class BaseCardInspection extends BaseStepper {
  @Prop({ type: [String, Number, Object, Array, Boolean] }) value!: any
  @Prop({ type: Boolean, default: true }) validated!: boolean
  @Prop({ type: Boolean, default: false }) disabledOpen!: boolean
  open = false

  get ripple () {
    const { validated, disabledOpen } = this

    if (disabledOpen) return false

    return validated
  }

  openCard () {
    const { validated, supervisor, disabledOpen } = this

    if (disabledOpen) return

    if (!validated && supervisor) return

    this.open = !this.open
    this.$emit('open-card', { open: this.open, category: this.categoryName })
  }

  @Watch('value', { immediate: true })
  onOpenChange (open: boolean) {
    this.open = open
  }
  }
