
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import { Debounce } from '@/utils/decorators'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { isArray } from 'class-validator'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  components: { GCostField, RatingCell, GFiles, GFormSlot, BasePanel },
})
  export default class InsidePanel extends BaseStepper {
  componentKeys = ['carCabin', 'carInteriorRoof', 'carTrunk', 'accessories', 'leftSeat', 'rightSeat']

  fieldsKeys = ['status']
  validation = false
  loadingSend = false
  cost = {
    carCabin: false,
    carInteriorRoof: false,
    carTrunk: false,
    accessories: false,
    leftSeat: false,
    rightSeat: false,
    passengerSeat: false,
    thirdRowSeat: false,
  }

  formData = {
    carCabin: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    carInteriorRoof: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    carTrunk: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    accessories: {
      status: {
        answer: null,
        provisional: false,
      },
      cost: undefined,
      photo: null,
    },
    leftSeat: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    rightSeat: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    passengerSeat: {
      present: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    thirdRowSeat: {
      present: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
  }

  errorIdentifierMessage = {
    carCabin: {
      provisional: '',
      status: '',
    },
    carInteriorRoof: {
      provisional: '',
      status: '',
    },
    carTrunk: {
      provisional: '',
      status: '',
    },
    accessories: {
      status: '',
      provisional: '',
    },
    leftSeat: {
      provisional: '',
      status: '',
    },
    rightSeat: {
      provisional: '',
      status: '',
    },
    passengerSeat: {
      provisional: '',
      status: '',
      present: '',
    },
    thirdRowSeat: {
      provisional: '',
      status: '',
      present: '',
    },
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get statusItems () {
    const {
      carCabin,
      carInteriorRoof,
      carTrunk,
      accessories,
      leftSeat,
      rightSeat,
      passengerSeat,
      thirdRowSeat,
    } = this

    return {
      carCabin: carCabin?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      carInteriorRoof: carInteriorRoof?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      carTrunk: carTrunk?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      accessories: accessories?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      leftSeat: leftSeat?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      rightSeat: rightSeat?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      passengerSeat: passengerSeat?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      thirdRowSeat: thirdRowSeat?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
    }
  }

  get statusAnswer () {
    const {
      carCabin,
      carInteriorRoof,
      carTrunk,
      accessories,
      leftSeat,
      rightSeat,
      passengerSeat,
      thirdRowSeat,
    } = this

    return {
      carCabin: this.getQuestionOptions(carCabin, 1)?.find(item => item.score === 0),
      carInteriorRoof: this.getQuestionOptions(carInteriorRoof, 1)?.find(item => item.score === 0),
      carTrunk: this.getQuestionOptions(carTrunk, 1)?.find(item => item.score === 0),
      accessories: this.getQuestionOptions(accessories, 1)?.find(item => item.score === 0),
      leftSeat: this.getQuestionOptions(leftSeat, 1)?.find(item => item.score === 0),
      rightSeat: this.getQuestionOptions(rightSeat, 1)?.find(item => item.score === 0),
      passengerSeat: this.getQuestionOptions(passengerSeat, 1)?.find(item => item.score === 0),
      thirdRowSeat: this.getQuestionOptions(thirdRowSeat, 1)?.find(item => item.score === 0),
    }
  }

  get thirdRowSeat () {
    return this.findComponentBySlug(this.components, 'third_row_seat')
  }

  get passengerSeat () {
    return this.findComponentBySlug(this.components, 'passenger_seat')
  }

  get rightSeat () {
    return this.findComponentBySlug(this.components, 'right_seat')
  }

  get leftSeat () {
    return this.findComponentBySlug(this.components, 'left_seat')
  }

  get accessories () {
    return this.findComponentBySlug(this.components, 'accessories')
  }

  get carTrunk () {
    return this.findComponentBySlug(this.components, 'car_trunk')
  }

  get carCabin () {
    return this.findComponentBySlug(this.components, 'car_cabin')
  }

  get carInteriorRoof () {
    return this.findComponentBySlug(this.components, 'car_interior_roof')
  }

  setErrorMessage (key, field) {
    if (field === 'status' && (this.formData[key][field].provisional === null || this.formData[key][field].provisional === undefined)) {
      this.errorIdentifierMessage[key].provisional = 'Este campo es requerido'
    } else if (field === 'status' && (this.formData[key][field].provisional !== null || this.formData[key][field].provisional !== undefined)) {
      this.errorIdentifierMessage[key].provisional = ''
    }

    this.errorIdentifierMessage[key][field] = 'Este campo es requerido'
  }

  validateFields (data, key) {
    const fields = ['present', 'status']
    let isValid = false

    const handleValidation = field => {
      const isArr = isArray(data[field]?.answer)
      const isNum = !isNaN(data[field]?.answer)

      if ((isNum && !data[field]?.answer) || (isArr && !data[field]?.answer.length)) {
        this.setErrorMessage(key, field)
        return true
      }
      this.errorIdentifierMessage[key][field] = ''
      return false
    }

    for (const field of fields) {
      if (key === 'passengerSeat' && field === 'present' && data[field]?.answer === this.questionPassengerNo.id) {
        return false
      }

      const isProvisional = data?.[field] && 'provisional' in data[field]
      const validNotProvisional = isProvisional === false && handleValidation(field)
      const validProvisional = (isProvisional === true && !data[field]?.provisional) && handleValidation(field)

      if (data[field]?.provisional) {
        this.validation = true
        this.formData[key].status.answer = []
      }

      if (validNotProvisional || validProvisional) {
        isValid = true
        break
      } else {
        this.errorIdentifierMessage[key][field] = ''
      }
    }

    return isValid
  }

  async send (component, data, field = null, extra = false) {
    if (this.validateFields(data, field)) {
      return
    }
    this.loadingSend = true
    const { step, photoProperties, inspection, cost, displayQualification } = this

    if (!extra) {
      await this.sendComponentOptics(component, this.formData[field], photoProperties, inspection.id)

      if (!cost[field] && data?.photo?.length) {
        await this.deleteFiles(data.photo)
      }
    } else {
      await this.sendExtra(data, component)
    }

    this.step = step + 1
    if (displayQualification) {
      const { categoryName, category, categoryQualificationConfig } = this
      this.$emit('input', { categoryName, category, categoryQualificationConfig })
    }
    this.$emit('update:inspection')
    this.loadingSend = false
  }

  async edit (component, cost, data) {
    const { presentData, statusData } = data
    const { idEmployee } = this
    await this.editMultipleComponent(component, statusData, idEmployee, 2)

    if (component.findInspectionParameterByOrder(1).assessment.id !== presentData) {
      const fields = {
        id: component.findInspectionParameterByOrder(1).id,
        id_inspection_assessment: presentData,
      }
      await this.pushData({ model: 'InspectionQualification', fields })
    }

    await this.pushData({
      model: 'InspectionInspectedComponent',
      fields: { id: component.id, cost },
    })
  }

  async sendExtra (data, component) {
    const { idProcess, idEmployee, inspection, photoProperties, cost, idProcessInspection } = this
    let resp

    const statusData = data?.status?.provisional ? [data.status.provisional] : data.status.answer
    const presentData = data.present.answer

    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent

      await this.edit(resp, data.cost, { presentData, statusData })
    } else {
      const fields = {
        id_inspection: inspection.id,
        id_inspection_component: component.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: [
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(1)?.id,
              id_inspection_assessment: presentData,
            },
            ...statusData?.map(status => {
              return {
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(2)?.id,
                id_inspection_assessment: status,
              }
            }) || [],
          ].filter(_ => _.id_inspection_assessment),
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })

      this.$emit('update:inspection')
    }

    if (!cost && data?.photo?.length) {
      await this.deleteFiles(data.photo)
    } else {
      await this.addFiles(data.photo, photoProperties, idProcess, resp)
    }
  }

  get allComponents () {
    const {
      carCabin,
      carInteriorRoof,
      carTrunk,
      accessories,
      leftSeat,
      rightSeat,
      passengerSeat,
      thirdRowSeat,
      selfPanel,
      componentKeys,
    } = this

    return {
      carCabin,
      carInteriorRoof,
      carTrunk,
      accessories,
      leftSeat,
      rightSeat,
      passengerSeat,
      thirdRowSeat,
      selfPanel,
      componentKeys,
    }
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    if (!val.selfPanel) return
    const { idProcess, inspection, componentKeys, autoClose, disabled, displayQualification } = this

    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification && this.step !== componentKeys.length - 1) {
          this.step = step
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        if (key === 'passengerSeat' || key === 'thirdRowSeat') {
          this.formData[key].present.answer = matchingInspectedComponent.findInspectionParameterByOrder(1)?.assessment?.id
          if (!matchingInspectedComponent.findInspectionParameterByOrder(2)?.assessment?.score) {
            this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(2)?.assessment?.id
            this.formData[key].status.answer = null
          } else {
            this.formData[key].status.provisional = false
            this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(2).map(item => item.assessment.id)
          }
        } else {
          if (!matchingInspectedComponent.findInspectionParameterByOrder(1)?.assessment?.score) {
            this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(1)?.assessment?.id
            this.formData[key].status.answer = null
          } else {
            this.formData[key].status.provisional = false
            this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(1).map(item => item.assessment.id)
          }
        }

        this.formData[key].cost = matchingInspectedComponent.cost
        if (matchingInspectedComponent.cost) {
          this.cost[key] = true
        }
      }
    }

    const watchFields = [...componentKeys, 'passengerSeat', 'thirdRowSeat']

    for (const [index, key] of watchFields.entries()) {
      await handleComponentChange(val[key], index + 1, key)
    }

    if (((this.formData.thirdRowSeat.status.provisional === false && this.formData.thirdRowSeat.status.answer) || (this.formData.thirdRowSeat.status.provisional)) && !displayQualification) {
      this.step = this.step + 1
    }
  }

  async getAutoGeneration (id) {
    return this.fetchData({
      query: { name: 'fetch', params: { id }, model: 'Generation' },
    })
  }

  @Watch('step', { immediate: true })
  async onStepChange (val) {
    const {
      componentKeys,
      selfPanel,
      inspection,
      categoryName,
      progress,
      displayRating,
      bodyWorkIsAllowed,
      category,
      categoryQualificationConfig,
    } = this

    const total = this.getTotalCost(this.formData)
    if (!componentKeys?.length) return
    if (!displayRating) {
      await this.updateCategoryProgress(inspection, categoryName, progress, 8)
    } else if (displayRating && selfPanel) {
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
    }

    const moreStep = bodyWorkIsAllowed ? 2 : 1
    if (val > componentKeys.length + moreStep && selfPanel) {
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
      this.$emit('inspection-category-done')
    }
  }

  get progress () {
    const { step, componentKeys, bodyWorkIsAllowed } = this
    const moreStep = bodyWorkIsAllowed ? 2 : 1
    const total = componentKeys.length + moreStep

    if (step > 1) {
      return ((step - 1) / total) * 100
    }

    return 0
  }

  get bodyWorkIsAllowed () {
    const { generation } = this

    if (!generation) return undefined

    return generation?.attributes.find(item => item.val.toUpperCase() === 'SUV' || item.val.toUpperCase() === 'MINIBUS')
  }

  get isDoorMoreThan4 () {
    const { generation } = this

    if (!generation) return undefined
    const attribute = generation?.attributes?.find(item => item.slug.toUpperCase() === 'DOORS')

    const isMore = parseInt(attribute?.val) > 3

    if (isMore) {
      this.formData.passengerSeat.present.answer = this.questionPassengerYes.id
    }

    return isMore
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  @Watch('formData', { immediate: true, deep: true })
  onFormDataChange (val) {
    const { fieldsKeys, componentKeys, validation, selfPanel } = this
    if (!selfPanel) {
      return
    }

    if (validation) {
      this.validation = false
      return
    }

    const watchFields = ['passengerSeat', 'thirdRowSeat']
    const extraField = ['present', 'status']
    componentKeys.forEach(key => {
      const data = val[key]
      this.cost[key] = this.isAnyFieldValid(fieldsKeys, data, this[key])

      this.initiateErrorMessage(key)
      if (!this.cost[key] && this.formData[key].cost) {
        this.formData[key].cost = 0
      }
    })

    watchFields.forEach(key => {
      const data = val[key]
      this.cost[key] = this.isAnyFieldValid(extraField, data, this[key])

      this.initiateErrorMessage(key)
      if (!this.cost[key] && this.formData[key].cost) {
        this.formData[key].cost = 0
      }
    })
  }

  initiateErrorMessage (key) {
    this.errorIdentifierMessage[key].status = ''
    this.errorIdentifierMessage[key].provisional = ''
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  get questionPassengerYes () {
    const { getQuestionOptionScoreZero, allComponents } = this

    return getQuestionOptionScoreZero(allComponents.passengerSeat, 1).find(item => item.name === 'Si')
  }

  get questionPassengerNo () {
    const { getQuestionOptionScoreZero, allComponents } = this

    return getQuestionOptionScoreZero(allComponents.passengerSeat, 1).find(item => item.name === 'No')
  }

  get questionThirdRowSeatYes () {
    const { getQuestionOptionScoreZero, allComponents } = this

    return getQuestionOptionScoreZero(allComponents.thirdRowSeat, 1)?.find(item => item.name === 'Si')
  }

  get questionThirdRowSeatNo () {
    const { getQuestionOptionScoreZero, allComponents } = this

    return getQuestionOptionScoreZero(allComponents.thirdRowSeat, 1)?.find(item => item.name === 'No')
  }

  get watchDataChange () {
    const { allComponents, formData: { thirdRowSeat: { present: { answer } } } } = this

    return {
      allComponents,
      answer,
    }
  }

  @Watch('watchDataChange', { immediate: true, deep: true })
  onResponseChange (val) {
    if (val.answer === this.questionThirdRowSeatNo?.id) {
      this.formData.thirdRowSeat.status.provisional = this.getQuestionOptionScoreZero(val.allComponents.thirdRowSeat, 2)[0].id
    }
  }

  get displayRating () {
    const { categoryName, inspection } = this

    const score = inspection?.metadata?.findCategoryByName(categoryName)?.score

    return score && score >= 0
  }
  }
