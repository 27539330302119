
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import { Debounce } from '@/utils/decorators'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'

@Component({
  components: { RatingCell, GFiles, BasePanel, GFormSlot },
})
  export default class PhotosPanel extends BaseStepper {
  componentKeys = []
  componentKeys2 = []
  fileParameters = []
  validation = false
  ready = false
  formData: Record<string, any> = {}
  loadingSend = false

  async mounted () {
    this.fileParameters = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'inspection' } } }, { file_type: { name: { _eq: 'photo' } } }] },
    })

    const sortedFileParameters = this.fileParameters.sort((a, b) => a.id - b.id)
    this.componentKeys = sortedFileParameters.filter(parameter => parameter.id >= 16 && parameter.id <= 24).map(fp => fp.description)
    this.componentKeys2 = sortedFileParameters.filter(parameter => parameter.id >= 25 && parameter.id <= 28).map(fp => fp.description)

    this.generateFormInfo(this.fileParameters)
  }

  generateFormInfo (components) {
    for (const field of components) {
      this.$set(this.formData, field.description, {
        photo: [],
      })
    }
    this.ready = true
  }

  async send (component, data) {
    const { step, photoProperties, inspection, fileParameters } = this

    const fileParameter = fileParameters.find(fp => fp.description === component)
    this.loadingSend = true
    await this.addFiles(data.photo, photoProperties, null, inspection, [fileParameter])
    this.step = step + 1
    this.loadingSend = false
  }

  get readyView () {
    const { ready, selfPanel } = this

    return {
      ready,
      selfPanel,
    }
  }

  @Watch('readyView', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    const { fileParameters, autoClose, disabled, inspection, displayQualification } = this

    if (!val.selfPanel) {
      return
    }

    const handleComponentChange = async (parameter, step, key) => {
      this.formData[key].photo = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: inspection.id } },
            { id_file_parameter: { _eq: parameter.id } },
          ],
        },
        force: true,
      })

      if (!autoClose && !disabled && this.formData[key].photo.length && !displayQualification) {
        this.step = step
      }
    }

    let index = 2
    for (const parameter of fileParameters) {
      await handleComponentChange(parameter, index, parameter.description)
      index++
    }
  }

  get viewStep () {
    const { step, componentKeys, componentKeys2 } = this

    return {
      step,
      componentKeys,
      componentKeys2,
    }
  }

  @Watch('viewStep', { immediate: true, deep: true })
  async onStepChange (val) {
    if (!val?.componentKeys?.length) {
      return
    }
    const { selfPanel, inspection, categoryName, progress, displayRating } = this
    const total = val.componentKeys.length + val.componentKeys2.length

    if (!displayRating) {
      await this.updateCategoryProgress(inspection, categoryName, progress, 15)
    }
    if (val.step > total && selfPanel) {
      await this.updateCategoryStatus(inspection, categoryName)
      this.$emit('inspection-category-done')
    }
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  get progress () {
    const { step, componentKeys, componentKeys2 } = this
    const total = componentKeys.length + componentKeys2.length
    if (step > 1) {
      return ((step - 1) / total) * 100
    }

    return 0
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  get displayRating () {
    const { categoryName, inspection } = this

    const score = inspection?.metadata?.findCategoryByName(categoryName)?.inspectorValidated

    return score && score >= 0
  }
  }
