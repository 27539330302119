
  import { Component, Watch } from 'vue-property-decorator'
  import BaseCardInspection from '@/components/forms/inspection/base/BaseCardInspection.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'

@Component({
  components: { RatingCell, BaseCardInspection },
})
  export default class ButtonCard extends BaseCardInspection {
  total = null

  get isMetadata () {
    const { inspection } = this
    return Boolean(inspection?.metadata)
  }

  @Watch('inspection.metadata', { immediate: true })
  onMetadataChange (metadata: any) {
    if (!metadata) return

    const { categoryName } = this
    const category = metadata.findCategoryByName(categoryName)

    if (!category) return

    this.total = category.total
  }

  get displayRating () {
    const { categoryName, inspection } = this

    const score = inspection?.metadata?.findCategoryByName(categoryName)?.score

    return score && score >= 0
  }

  get canValidated () {
    const { supervisor, categoryName, inspection, categoryError } = this

    if (categoryError) {
      return true
    }

    if (!supervisor) {
      return null
    }

    return inspection.metadata?.findCategoryByName(categoryName)?.inspectorValidated === true
  }

  get supervisorValidated () {
    const { categoryName, inspection, supervisor } = this

    if (!supervisor) return null

    return !inspection.metadata?.findCategoryByName(categoryName)?.validated
  }

  get color () {
    const { canValidated, supervisor, categoryError, disabledOpen } = this
    if (disabledOpen) {
      return 'greyShadow'
    }

    if (categoryError) {
      return 'red'
    }

    if (!supervisor) {
      return 'primary'
    }

    return canValidated ? 'primary' : 'greyShadow'
  }
  }
