
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import { Debounce } from '@/utils/decorators'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { isArray } from 'class-validator'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import { RULES } from '@/components/forms'
  import { getProgressColor, minLength } from '@/utils/general'
  import { ComponentCategory, ComponentValue } from '@/entities/vehicle'
  import { sortArray } from '@/utils/array/manipulation'
  import { getFromLocalStorage, insertIntoLocalStorage } from '@/utils/data'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  methods: { sortArray, minLength },
  components: { GCostField, RatingCell, GFiles, BasePanel, GFormSlot },
})
  export default class TireAndWheelPanel extends BaseStepper {
  @Prop({ type: Object }) exterior!: ComponentCategory
  equipment = {
    present: [],
    extra: [],
  }

  initStep = 1
  loadingSend = false
  alloyWheel: ComponentValue = null
  wheelCovers: ComponentValue = null
  capScrew: ComponentValue = null
  equipmentKeys = {
    alloyWheel: 'Llantas de aleación',
    wheelCovers: 'Tapas de ruedas',
    capScrew: 'Pernos de seguridad',
  }

  wheelCost = {
    rightFrontWheel: {
      cost: null,
      disabled: false,
      new: false,
    },
    rightRearWheel: {
      cost: null,
      disabled: false,
      new: false,
    },
    leftRearWheel: {
      cost: null,
      disabled: false,
      new: false,
    },
    leftFrontWheel: {
      cost: null,
      disabled: false,
      new: false,
    },
  }

  fieldsEquipment = ['alloyWheel', 'wheelCovers', 'capScrew']
  attributes = []
  componentKeys = [
    'alloyWheel', 'wheelCovers', 'capScrew', 'rightFrontTire', 'rightFrontWheel', 'leftFrontTire', 'leftFrontWheel', 'leftRearTire', 'leftRearWheel', 'rightRearTire', 'rightRearWheel',
    'spareTire', 'spareWheel']

  fieldsKeys = ['original', 'level', 'status', 'new']
  rims = Array.from({ length: 11 }, (_, i) => `R${i + 13}`)

  multipleOfFive = RULES.isMultipleOfFive
  removeSpareOption = false
  validation = false
  loading = true
  tireBrands = {
    all: [],
    rightFrontWheel: [],
    rightRearWheel: [],
    leftRearWheel: [],
    leftFrontWheel: [],
    spareWheel: [],
  }

  cost = {
    rightFrontTire: false,
    rightFrontWheel: false,
    rightRearTire: false,
    rightRearWheel: false,
    leftRearTire: false,
    leftRearWheel: false,
    leftFrontTire: false,
    leftFrontWheel: false,
    spareTire: false,
    spareWheel: false,
    alloyWheel: false,
    wheelCovers: false,
    capScrew: false,
  }

  formData = {
    alloyWheel: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    wheelCovers: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    capScrew: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    rightFrontTire: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    rightFrontWheel: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      level: {
        answer: null,
      },
      new: {
        answer: null,
      },
      cost: undefined,
      photo: null,
      detail: {
        brand: null,
        width: null,
        height: null,
        diameter: null,
      },
    },
    rightRearTire: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintStatus: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    rightRearWheel: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      level: {
        answer: null,
      },
      new: {
        answer: null,
      },
      cost: undefined,
      photo: null,
      detail: {
        brand: null,
        width: null,
        height: null,
        diameter: null,
      },
    },
    leftRearTire: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    leftRearWheel: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      level: {
        answer: null,
      },
      new: {
        answer: null,
      },
      cost: undefined,
      photo: null,
      detail: {
        brand: null,
        width: null,
        height: null,
        diameter: null,
      },
    },
    leftFrontTire: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    leftFrontWheel: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      level: {
        answer: null,
      },
      new: {
        answer: null,
      },
      cost: undefined,
      photo: null,
      detail: {
        brand: null,
        width: null,
        height: null,
        diameter: null,
      },
    },
    spareTire: {
      should: {
        answer: null,
      },
      present: {
        answer: null,
      },
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    spareWheel: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      level: {
        answer: null,
      },
      cost: undefined,
      photo: null,
      detail: {
        brand: null,
        width: null,
        height: null,
        diameter: null,
      },
    },
  }

  alertWheels = {
    front: '',
    rear: '',
  }

  errorIdentifierMessage = {
    alloyWheel: {
      original: '',
      status: '',
    },
    wheelCovers: {
      original: '',
      status: '',
    },
    capScrew: {
      original: '',
      status: '',
    },
    rightFrontTire: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
      paintStatus: '',
      paintProvisional: '',
    },
    rightFrontWheel: {
      original: '',
      provisional: '',
      status: '',
      new: '',
    },
    rightRearTire: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
      paintStatus: '',
      paintProvisional: '',
    },
    rightRearWheel: {
      original: '',
      provisional: '',
      status: '',
      new: '',
    },
    leftRearTire: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
      paintStatus: '',
      paintProvisional: '',
    },
    leftRearWheel: {
      original: '',
      provisional: '',
      status: '',
      new: '',
    },
    leftFrontTire: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
      paintStatus: '',
      paintProvisional: '',
    },
    leftFrontWheel: {
      original: '',
      provisional: '',
      status: '',
      new: '',
    },
    spareTire: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
      paintStatus: '',
      paintProvisional: '',
      should: '',
      present: '',
    },
    spareWheel: {
      original: '',
      provisional: '',
      status: '',
      new: '',
    },
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get aestheticEquipment () {
    return this.findComponentBySlug(this.exterior.components, 'aesthetic_equipment')
  }

  get statusItems () {
    const {
      rightFrontTire,
      rightFrontWheel,
      rightRearTire,
      rightRearWheel,
      leftRearTire,
      leftRearWheel,
      leftFrontTire,
      leftFrontWheel,
      spareTire,
      spareWheel,
    } = this

    return {
      rightFrontTire: rightFrontTire.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      rightFrontWheel: rightFrontWheel.findQuestion(6).inspectionAssessments.filter(item => item.score !== 0),
      rightRearTire: rightRearTire.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      rightRearWheel: rightRearWheel.findQuestion(6).inspectionAssessments.filter(item => item.score !== 0),
      leftRearTire: leftRearTire.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      leftRearWheel: leftRearWheel.findQuestion(6).inspectionAssessments.filter(item => item.score !== 0),
      leftFrontTire: leftFrontTire.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      leftFrontWheel: leftFrontWheel.findQuestion(6).inspectionAssessments.filter(item => item.score !== 0),
      spareTire: spareTire.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      spareWheel: spareWheel.findQuestion(6).inspectionAssessments.filter(item => item.score !== 0),
    }
  }

  get statusAnswer () {
    const {
      rightFrontTire,
      rightFrontWheel,
      rightRearTire,
      rightRearWheel,
      leftRearTire,
      leftRearWheel,
      leftFrontTire,
      leftFrontWheel,
      spareTire,
      spareWheel,
    } = this
    return {
      rightFrontTire: rightFrontTire.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      rightFrontWheel: rightFrontWheel.findQuestion(6).inspectionAssessments.find(item => item.score === 0),
      rightRearTire: rightRearTire.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      rightRearWheel: rightRearWheel.findQuestion(6).inspectionAssessments.find(item => item.score === 0),
      leftRearTire: leftRearTire.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      leftRearWheel: leftRearWheel.findQuestion(6).inspectionAssessments.find(item => item.score === 0),
      leftFrontTire: leftFrontTire.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      leftFrontWheel: leftFrontWheel.findQuestion(6).inspectionAssessments.find(item => item.score === 0),
      spareTire: spareTire.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      spareWheel: spareWheel.findQuestion(6).inspectionAssessments.find(item => item.score === 0),
    }
  }

  get spareWheel () {
    return this.findComponentBySlug(this.components, 'spare_wheel')
  }

  get spareTire () {
    return this.findComponentBySlug(this.components, 'spare_tire')
  }

  get leftFrontWheel () {
    return this.findComponentBySlug(this.components, 'left_front_wheel')
  }

  get leftFrontTire () {
    return this.findComponentBySlug(this.components, 'left_front_tire')
  }

  get leftRearWheel () {
    return this.findComponentBySlug(this.components, 'left_rear_wheel')
  }

  get leftRearTire () {
    return this.findComponentBySlug(this.components, 'left_rear_tire')
  }

  get leftRocker () {
    return this.findComponentBySlug(this.components, 'left_rear_wheel')
  }

  get rightRearWheel () {
    return this.findComponentBySlug(this.components, 'right_rear_wheel')
  }

  get rightRearTire () {
    return this.findComponentBySlug(this.components, 'right_rear_tire')
  }

  get rightFrontWheel () {
    return this.findComponentBySlug(this.components, 'right_front_wheel')
  }

  get rightFrontTire () {
    return this.findComponentBySlug(this.components, 'right_front_tire')
  }

  get tireBrand () {
    return this.findComponentBySlug(this.components, 'tire_brand')
  }

  get uneven () {
    return ((this.step - 1) % 2) === 1
  }

  setErrorMessage (key, field) {
    if (field === 'status' && (this.formData[key][field].provisional === null || this.formData[key][field].provisional === undefined)) {
      this.errorIdentifierMessage[key].provisional = 'Este campo es requerido'
    } else if (field === 'status' && (this.formData[key][field].provisional !== null || this.formData[key][field].provisional !== undefined)) {
      this.errorIdentifierMessage[key].provisional = ''
    }

    if (field === 'paintStatus' && (this.formData[key][field].provisional === null || this.formData[key][field].provisional === undefined)) {
      this.errorIdentifierMessage[key].paintProvisional = 'Este campo es requerido'
    } else if (field === 'paintStatus' && (this.formData[key][field].provisional !== null || this.formData[key][field].provisional !== undefined)) {
      this.errorIdentifierMessage[key].paintProvisional = ''
    }

    this.errorIdentifierMessage[key][field] = 'Este campo es requerido'
  }

  handleValidation (field, data, key) {
    const isArr = isArray(data[field]?.answer)
    const isNum = !isNaN(data[field]?.answer)

    if ((isNum && !data[field]?.answer) || (isArr && !data[field]?.answer.length)) {
      this.setErrorMessage(key, field)
      return true
    }
    this.errorIdentifierMessage[key][field] = ''
    return false
  }

  validateSpare (key) {
    if (key.includes('spare') && this.formData.spareTire?.should.answer === this.getQuestionOption(this.spareTire, 1, true, 'No').id) {
      return false
    }

    if (key.includes('spareTire') && !this.formData[key]?.should.answer) {
      this.setErrorMessage(key, 'should')

      return true
    }
    if (key.includes('spareTire') && !this.formData[key]?.present.answer && this.formData[key]?.should.answer === this.getQuestionOption(this.spareTire).id) {
      this.setErrorMessage(key, 'present')
      return true
    }

    if (key.includes('spareTire') && this.formData[key]?.present?.answer === this.getQuestionOption(this.spareTire, 2, false, 'No').id) {
      return false
    }

    if (key.includes('spareWheel') && this.formData.spareTire?.present?.answer === this.getQuestionOption(this.spareTire, 2, false, 'No').id) {
      return false
    }
    return undefined
  }

  validateFields (data, key, component) {
    const fields = ['original', 'status', 'new']
    let isValid = false

    const spareValidation = this.validateSpare(key)

    if (key.includes('spare') && spareValidation) {
      return true
    } else if (key.includes('spare') && spareValidation === false) {
      return false
    }

    for (const field of fields) {
      if (data?.[field]) {
        if (this.fieldsEquipment.includes(key) && data[field] !== this.getQuestionOption(component).id && field === 'status') {
          return false
        }

        const isProvisional = 'provisional' in data[field]
        const validNotProvisional = isProvisional === false && this.handleValidation(field, data, key)
        const validProvisional = (isProvisional === true && !data[field]?.provisional) && this.handleValidation(field, data, key)

        if (data[field]?.provisional) {
          this.validation = true
          this.formData[key].status.answer = []
        }

        if (validNotProvisional || validProvisional) {
          isValid = true
          break
        } else {
          this.errorIdentifierMessage[key][field] = ''
        }
      }
    }

    return isValid
  }

  async send (component, data, field = [], equipment = false) {
    let shouldReturn = false
    data.forEach((item, index) => {
      if (this.validateFields(item, field[index], component[index])) {
        shouldReturn = true
      }
    })

    if (shouldReturn) {
      return
    }

    this.handleSend()
    let newInspection = null
    const { step, photoProperties, inspection, cost, displayQualification, wheelCovers } = this
    if (equipment) {
      if (component[0].value === 'Llantas de aleación') {
        await this.checkRemove(data[0], wheelCovers)
      }
      await this.sendEquipment(data[0], component[0], field[0])
      if (!cost[field[0]] && data?.photo?.length) {
        await this.deleteFiles(data.photo)
      }
    } else {
      if (!field.some(f => f.includes('spare'))) {
        await this.sendComponentPieceAttach(component[0], this.formData[field[0]], photoProperties, inspection.id)
      } else {
        newInspection = await this.removeSpare(component, data)
        await this.sendComponentSpare(component[0], this.formData[field[0]], photoProperties, inspection.id, newInspection)
      }
      await this.sendComponentWheel(component[1], this.formData[field[1]], photoProperties, inspection.id, newInspection)
      await this.updateWheelCostToUpdate(component[1], this.formData[field[1]])
      if (!data[0].cost && data[0]?.photo?.length) {
        await this.deleteFiles(data.photo)
      }
      if (!data[1].cost && data[1]?.photo?.length) {
        await this.deleteFiles(data.photo)
      }
    }

    this.emitEvent(step, displayQualification)
  }

  emitEvent (step, displayQualification) {
    this.emitEvents(step, displayQualification)
    this.loadingSend = false
  }

  handleSend () {
    this.loadingSend = true
    this.setWatchCost()
  }

  async removeQualifications (component) {
    const idsQualification = component.inspectionQualifications.map(_ => _.id)
    await Promise.all((idsQualification as Record<string, any>).map(async _ => {
      await this.deleteInspectionQualification(_)
    }))
    await this.deleteInspectionInspectedComponent(component.id)
  }

  async removeSpare (component, data) {
    const { inspection } = this

    const matchingInspectedComponentTire = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component[0]?.inspectionComponent?.id
    )

    const matchingInspectedComponentWheel = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component[1]?.inspectionComponent?.id
    )

    if (data[0].should.answer === this.getQuestionOption(component[0], 1, true, 'No').id) {
      if (matchingInspectedComponentTire) {
        await this.removeQualifications(matchingInspectedComponentTire)

        this.formData.spareTire = {
          should: {
            answer: data[0].should.answer,
          },
          present: {
            answer: null,
          },
          original: {
            answer: null,
          },
          status: {
            answer: null,
            provisional: null,
          },
          cost: 0,
          photo: data[0].photo,
        }
      }

      if (matchingInspectedComponentWheel) {
        await this.removeQualifications(matchingInspectedComponentWheel)
        this.formData.spareWheel = {
          original: {
            answer: null,
          },
          status: {
            answer: null,
            provisional: null,
          },
          level: {
            answer: null,
          },
          cost: 0,
          photo: data[0].photo,
          detail: {
            brand: null,
            width: null,
            height: null,
            diameter: null,
          },
        }
      }
    } else {
      if (matchingInspectedComponentTire) {
        await this.removeQualifications(matchingInspectedComponentTire)
      }
      if (matchingInspectedComponentWheel) {
        await this.removeQualifications(matchingInspectedComponentWheel)
      }
    }

    return this.fetchData({ query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } }, force: true })
  }

  async checkRemove (data, component) {
    const { inspection, questionAlloyYes } = this

    const resp = data.status.answer === questionAlloyYes.id
    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent && resp) {
      const idsQualification = matchingInspectedComponent.inspectionQualifications.map(_ => _.id)
      await Promise.all((idsQualification as Record<string, any>).map(async _ => {
        await this.deleteInspectionQualification(_)
      }))
      await this.deleteInspectionInspectedComponent(matchingInspectedComponent.id)

      await this.deleteFiles(this.formData.wheelCovers.photo)
      this.formData.wheelCovers = {
        original: {
          answer: null,
        },
        status: {
          answer: null,
        },
        cost: undefined,
        photo: null,
      }
    }
  }

  emitEvents (step, displayQualification) {
    this.step = step + 1

    if (displayQualification) {
      const { categoryName, category, categoryQualificationConfig } = this
      this.$emit('input', { categoryName, category, categoryQualificationConfig })
    }
    this.$emit('update:inspection')
  }

  async updateWheelCostToUpdate (component, data) {
    const { wheelCost, rightFrontWheel, leftRearWheel, leftFrontWheel, rightRearWheel, inspection } = this

    if (component.slug === 'left_front_wheel' && wheelCost.rightFrontWheel.new && data.cost) {
      this.wheelCost.leftFrontWheel.cost = data.cost
      await this.updateWheel(rightFrontWheel, data.cost, 'rightFrontWheel')
      this.wheelCost.rightFrontWheel.cost = data.cost
    }

    if (component.slug === 'right_front_wheel' && wheelCost.leftFrontWheel.new && data.cost) {
      this.wheelCost.rightFrontWheel.cost = data.cost
      await this.updateWheel(leftFrontWheel, data.cost, 'leftFrontWheel')
      this.wheelCost.leftFrontWheel.cost = data.cost
    }

    if (component.slug === 'left_rear_wheel' && wheelCost.rightRearWheel.new && data.cost) {
      this.wheelCost.leftRearWheel.cost = data.cost
      await this.updateWheel(rightRearWheel, data.cost, 'rightRearWheel')
      this.wheelCost.rightRearWheel.cost = data.cost
    }

    if (component.slug === 'right_rear_wheel' && wheelCost.leftRearWheel.new && data.cost) {
      this.wheelCost.rightRearWheel.cost = data.cost
      await this.updateWheel(leftRearWheel, data.cost, 'leftRearWheel')
      this.wheelCost.leftRearWheel.cost = data.cost
    }
    insertIntoLocalStorage('inspection', inspection.id, this.wheelCost)
  }

  async updateWheel (component, cost, field) {
    const { inspection, getQuestionOption } = this
    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (!matchingInspectedComponent || !cost) return

    await this.editComponentPiece(matchingInspectedComponent, { new: { answer: getQuestionOption(component, 8, false, 'Si').id } }, 'new', 8)
    await this.pushData({
      model: 'InspectionInspectedComponent',
      fields: { id: matchingInspectedComponent.id, cost },
    })

    this.formData[field].new.answer = getQuestionOption(component, 8, false, 'Si').id
    this.formData[field].cost = cost
  }

  async sendEquipment (data, component, key) {
    const {
      idProcess,
      idEmployee,
      inspection,
      photoProperties,
      cost,
      equipmentKeys,
      generation,
      attributes,
      idProcessInspection,
    } = this
    let resp

    const originalData = data?.original?.answer
    const statusData = data.status.answer

    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent

      await this.edit(resp, data.cost, { originalData, statusData })
    } else {
      const fields = {
        id_inspection: inspection.id,
        id_inspection_component: component.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: [
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(1)?.id,
              id_inspection_assessment: originalData,
            },
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(2)?.id,
              id_inspection_assessment: statusData,
            },
          ].filter(_ => _.id_inspection_assessment),
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })

      this.$emit('update:inspection')
    }

    if (!cost && data?.photo?.length) {
      await this.deleteFiles(data.photo)
    } else {
      await this.addFiles(data.photo, photoProperties, idProcess, resp)
    }

    const existEquipment = this.getAttributes(generation, equipmentKeys, key)
    const extraEquipment = attributes.find(att => att.val === equipmentKeys[key])
    const isPresentEquipment = originalData === this.getQuestionOption(component).id && statusData === this.getQuestionOption(component, 2).id

    if (!existEquipment && !extraEquipment && isPresentEquipment) {
      await this.insertUpdateDealAutoAttribute(inspection.id, inspection.deal.id, component, component.component.id)
      if (key === 'alloyWheel') {
        const extra = attributes.find(att => att.val === equipmentKeys.wheelCovers)
        if (extra) {
          await this.deleteDealAutoAttribute([extra])
        }
      }
    } else if (extraEquipment && !isPresentEquipment) {
      await this.deleteDealAutoAttribute([extraEquipment])
    }
  }

  getAttributes (generation, equipmentKeys, key) {
    return generation?.attributes?.find(att => att.value === equipmentKeys[key])
  }

  async edit (component, cost, data) {
    const { originalData, statusData } = data
    const { alloyWheel, idEmployee } = this

    if (component.findInspectionParameterByOrder(1).assessment.id !== originalData) {
      const fields = {
        id: component.findInspectionParameterByOrder(1).id,
        id_inspection_assessment: originalData,
      }
      await this.pushData({ model: 'InspectionQualification', fields })
    }
    const isAssessment = component?.findInspectionParameterByOrder(2)?.assessment?.id

    if (isAssessment && statusData && isAssessment === statusData) {
      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields: { id: component.id, cost },
      })
      return
    }
    if (isAssessment && statusData && isAssessment !== statusData) {
      const fields = {
        id: component.findInspectionParameterByOrder(2).id,
        id_inspection_assessment: statusData,
      }
      await this.pushData({ model: 'InspectionQualification', fields })
    } else if (statusData) {
      const fields = {
        id_employee: idEmployee,
        id_inspection_parameter: alloyWheel?.findQuestion(2)?.id,
        id_inspection_assessment: statusData,
        id_inspection_inspected_component: component.id,
      }
      await this.pushData({
        model: 'InspectionQualification',
        fields,
      })
    } else if (component?.findInspectionParameterByOrder(2)?.id) {
      await this.removeData({
        model: 'InspectionQualification',
        fields: { id: component.findInspectionParameterByOrder(2).id },
      })
    }

    await this.pushData({
      model: 'InspectionInspectedComponent',
      fields: { id: component.id, cost },
    })
  }

  get allComponents () {
    const {
      rightFrontTire,
      rightFrontWheel,
      rightRearTire,
      rightRearWheel,
      leftRocker,
      leftRearTire,
      leftRearWheel,
      leftFrontTire,
      leftFrontWheel,
      spareTire,
      spareWheel,
      selfPanel,
      alloyWheel,
      wheelCovers,
      capScrew,
    } = this

    return {
      alloyWheel,
      wheelCovers,
      capScrew,
      rightFrontTire,
      rightFrontWheel,
      rightRearTire,
      rightRearWheel,
      leftRocker,
      leftRearTire,
      leftRearWheel,
      leftFrontTire,
      leftFrontWheel,
      spareTire,
      spareWheel,
      selfPanel,
    }
  }

  calculatedStep (step: number, initStep: number): void {
    if (!step) return
    const init = initStep === 1 ? 3 : 4

    if (step <= init) {
      if (init === 4 && step === 3) {
        this.step = 4
      } else {
        this.step = step
      }
      return
    }

    switch (step - init) {
      case 1:
        this.step = step
        break
      case 2:
      case 3:
        this.step = step - 1
        break
      case 4:
        this.step = step - 2
        break
      case 5:
        this.step = init === 4 ? step - 2 : step - 1
        break
      case 6:
        this.step = step - 3
        break
      default:
        this.step = init === 4 ? step - 3 : step - 4
    }
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    if (!val.selfPanel) return
    const { idProcess, inspection, componentKeys, autoClose, disabled, displayQualification } = this
    let localStep = null
    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification) {
          localStep = step
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })
        this.setData(matchingInspectedComponent, key, component)
        this.formData[key].cost = matchingInspectedComponent.cost
      }
    }

    for (const [index, key] of componentKeys.entries()) {
      if (key in this.tireBrands) {
        this.tireBrands[key] = this.tireBrands.all
      }
      await handleComponentChange(val[key], index + 1, key)
    }

    const storeData = getFromLocalStorage('inspection', inspection.id)
    if (storeData) {
      this.wheelCost = storeData
    }
    this.loading = false
    this.calculatedStep(localStep, this.initStep)
  }

  calculateScore () {
    const tireAndWheelScore = this.getScore(this.categoryQualificationConfig, this.category, this.inspection, this.categoryName)

    this.$emit('save-equipment-score', this.equipmentScore)

    return tireAndWheelScore
  }

  getScore (categoryQualificationConfig, category, inspection, name) {
    if (!category?.id || !categoryQualificationConfig) return undefined

    const score = inspection.getCategoryScore(category.id) + this.equipmentScore

    let configValue: number | undefined

    Object.entries(categoryQualificationConfig?.[name]?.scores).forEach(([key, value]) => {
      const config = value as Record<string, any>
      if (score >= config.min && (config.max === null || score <= config.max)) {
        configValue = config.value
      }
    })

    return configValue
  }

  setData (matchingInspectedComponent, key, component) {
    if (this.fieldsEquipment.includes(key)) {
      this.formData[key].original.answer = matchingInspectedComponent?.findInspectionParameterByOrder(1)?.assessment?.id
      if (matchingInspectedComponent?.findInspectionParameterByOrder(2)?.assessment?.id) {
        this.formData[key].status.answer = matchingInspectedComponent.findInspectionParameterByOrder(2).assessment?.id
      }
      return
    }

    if (key.includes('Tire')) {
      if (key.includes('spare')) {
        this.setSpareTire(matchingInspectedComponent, key)
      } else {
        this.formData[key].original.answer = matchingInspectedComponent?.findInspectionParameterByOrder(1)?.assessment?.id
        if (!matchingInspectedComponent.findInspectionParameterByOrder(2)?.assessment?.score) {
          this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(2)?.assessment?.id
          this.formData[key].status.answer = null
        } else {
          this.formData[key].status.provisional = false
          this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(2).map(item => item.assessment.id)
        }
      }
    } else {
      this.setWheelData(matchingInspectedComponent, key, component)
    }
  }

  setSpareTire (matchingInspectedComponent, key) {
    this.formData[key].should.answer = matchingInspectedComponent?.findInspectionParameterByOrder(1)?.assessment?.id
    this.formData[key].present.answer = matchingInspectedComponent?.findInspectionParameterByOrder(2)?.assessment?.id
    this.formData[key].original.answer = matchingInspectedComponent?.findInspectionParameterByOrder(3)?.assessment?.id
    if (!matchingInspectedComponent.findInspectionParameterByOrder(4)?.assessment?.score) {
      this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(4)?.assessment?.id
      this.formData[key].status.answer = null
    } else {
      this.formData[key].status.provisional = false
      this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(4).map(item => item.assessment.id)
    }
  }

  setWheelData (matchingInspectedComponent, key, component) {
    this.formData[key].detail.brand = matchingInspectedComponent?.findInspectionParameterByOrder(1)?.value
    this.formData[key].detail.height = matchingInspectedComponent?.findInspectionParameterByOrder(2)?.value
    this.formData[key].detail.width = matchingInspectedComponent?.findInspectionParameterByOrder(3)?.value
    this.formData[key].detail.diameter = matchingInspectedComponent?.findInspectionParameterByOrder(4)?.value
    this.formData[key].original.answer = matchingInspectedComponent?.findInspectionParameterByOrder(5)?.assessment?.id
    const idAnswer = matchingInspectedComponent.findInspectionParameterByOrder(6)?.assessment?.id

    this.formData[key].level.answer = sortArray(component.inspectionComponent.getComponentOptionByOrder(6), 'name', true, '%').findIndex(option => {
      return option.id === idAnswer
    })

    if (!matchingInspectedComponent.findInspectionParameterByOrder(7)?.assessment?.score) {
      this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(7)?.assessment?.id
      this.formData[key].status.answer = null
    } else {
      this.formData[key].status.provisional = false
      this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(7).map(item => item.assessment.id)
    }

    if ('new' in this.formData[key]) {
      this.formData[key].new.answer = matchingInspectedComponent?.findInspectionParameterByOrder(8)?.assessment?.id
    }
  }

  @Watch('step', { immediate: true })
  async onStepChange (val) {
    const {
      selfPanel,
      displayRating,
      inspection,
      categoryName,
      progress,
      category, categoryQualificationConfig,
      initStep,
    } = this
    if (!selfPanel) return

    const total = this.getTotalCost(this.formData)
    if (!displayRating) {
      await this.updateCategoryProgress(inspection, categoryName, progress, 7)
    } else {
      this.$emit('input', { categoryName, category, categoryQualificationConfig, score: this.calculateScore(), total })
    }

    const init = initStep === 1 ? 7 : 8
    this.setWatchCost()

    if (val > init && selfPanel) {
      this.$emit('input', { categoryName, category, categoryQualificationConfig, score: this.calculateScore(), total })
      this.$emit('inspection-category-done')
    }
  }

  get progress () {
    const { step } = this

    if (step > 1) {
      return ((step - 1) / 8) * 100
    }

    return 0
  }

  setWatchCost () {
    const { watchRightFrontWheel, watchLeftFrontWheel, watchLeftRearWheel, watchRightRearWheel } = this
    if (watchLeftFrontWheel.new) {
      this.setWheelCost('rightFrontWheel', 'leftFrontWheel')
    }
    if (watchRightFrontWheel.new) {
      this.setWheelCost('leftFrontWheel', 'rightFrontWheel')
    }
    if (watchLeftRearWheel.new) {
      this.setWheelCost('rightRearWheel', 'leftRearWheel')
    }
    if (watchRightRearWheel.new) {
      this.setWheelCost('leftRearWheel', 'rightRearWheel')
    }
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  get dataToWatch () {
    const { formData, step } = this

    return {
      data: formData,
      step,
    }
  }

  @Watch('dataToWatch', { immediate: true, deep: true })
  @Debounce(100)
  onFormDataChange (val) {
    const { componentKeys, validation, allComponents, selfPanel } = this
    if (!selfPanel) {
      return
    }
    if (validation) {
      this.validation = false
      return
    }
    const init = this.initStep === 1 ? 2 : 3

    if (val.step > init) {
      const step = init === 2 ? val.step + 1 : val.step
      this.getKey(val.data, step)
    } else {
      const key = componentKeys[val.step - 1]
      const data = val.data[key]
      this.cost[key] = this.isAnyFieldValid(['original', 'status'], data, allComponents[key])
      this.initiateErrorMessage(key)
      if (!this.cost[key] && this.formData[key]?.cost) {
        this.formData[key].cost = 0
      }
    }
  }

  get equipmentScore () {
    const { formData: { alloyWheel, wheelCovers, capScrew }, alloyWheel: component } = this

    const totalAlloyWheel = this.getTotalScore(['original', 'status'], alloyWheel, component)
    const totalWheelCovers = this.getTotalScore(['original', 'status'], wheelCovers, component)
    const totalCapScrew = this.getTotalScore(['original', 'status'], capScrew, component)

    return totalAlloyWheel + totalWheelCovers + totalCapScrew
  }

  validateCost (form, key, key2) {
    const data = form[key]
    const data2 = form[key2]

    const fields = key.includes('spare') ? ['should', 'present', 'original', 'status'] : ['original', 'status']
    const alternativeValidation = key.includes('spareTire') ? this.isSpareCost : false
    this.cost[key] = this.isAnyFieldValid(fields, data, this.allComponents[key]) || alternativeValidation
    this.cost[key2] = this.isAnyFieldValid(this.fieldsKeys, data2, this.allComponents[key2], 5)

    this.initiateErrorMessage(key)
    this.initiateErrorMessage(key2)

    if (!this.cost[key] && this.formData[key]?.cost) {
      this.formData[key].cost = 0
    }

    if (this.formData[key2].level.answer !== null) {
      const items = this.allComponents[key2].inspectionComponent.getComponentOptionByOrder(6)
      const item = sortArray(items, 'name', true, '%')[this.formData[key2].level.answer]

      this.cost[key2] = this.cost[key2] || Boolean(item?.score)
    }

    if (!this.cost[key2] && this.formData[key2].cost) {
      this.formData[key2].cost = 0
    }
  }

  getKey (form, step) {
    const { componentKeys } = this

    let key = null
    let key2 = null
    if (step === 4) {
      key = componentKeys[step - 1]
      key2 = componentKeys[step]
    }
    if (step === 5) {
      key = componentKeys[step]
      key2 = componentKeys[step + 1]
    }

    if (step === 6) {
      key = componentKeys[step + 1]
      key2 = componentKeys[step + 2]
    }

    if (step === 7) {
      key = componentKeys[step + 2]
      key2 = componentKeys[step + 3]
    }

    if (step === 8) {
      key = componentKeys[step + 3]
      key2 = componentKeys[step + 4]
    }

    if (!key) return
    this.validateCost(form, key, key2)
  }

  initiateErrorMessage (key) {
    if (!key) return

    if ('should' in this.errorIdentifierMessage[key]) {
      this.errorIdentifierMessage[key].should = ''
    }
    if ('present' in this.errorIdentifierMessage[key]) {
      this.errorIdentifierMessage[key].present = ''
    }
    this.errorIdentifierMessage[key].original = ''
    this.errorIdentifierMessage[key].status = ''
    this.errorIdentifierMessage[key].provisional = ''
  }

  getDescriptionWheel (data) {
    if (!data?.height || !data?.width || !data?.diameter) return null
    return `Medida: ${data.height}/${data.width}/${data.diameter}`
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  @Watch('inspection', { immediate: true, deep: true })
  async onInspectionChange (val): Promise<void> {
    const { selfPanel } = this
    if (!val?.id && !selfPanel) return

    const { idProcessInspection, aestheticEquipment } = this
    this.attributes = await this.getDealAutoAttribute(aestheticEquipment.id, idProcessInspection, val.id)
  }

  get isAlloyWheel () {
    const { generation, equipmentKeys, getQuestionOption, wheelCovers } = this
    if (!generation) return null

    const isPresent = generation?.attributes?.find(val => val.val === equipmentKeys.alloyWheel)
    if (isPresent) {
      this.formData.alloyWheel.original.answer = this.questionYes?.id
      this.formData.wheelCovers.original.answer = getQuestionOption(wheelCovers, 1, true, 'No').id
    }
    return Boolean(isPresent)
  }

  async getAutoGeneration (id) {
    return this.fetchData({
      query: { name: 'fetch', params: { id }, model: 'Generation' },
    })
  }

  @Watch('aestheticEquipment', { immediate: true, deep: true })
  async onComfortChange (val) {
    if (!val) return null
    const { equipmentKeys } = this

    this.alloyWheel = val.values.find(item => item.value === equipmentKeys.alloyWheel)
    this.wheelCovers = val.values.find(item => item.value === equipmentKeys.wheelCovers)
    this.capScrew = val.values.find(item => item.value === equipmentKeys.capScrew)
  }

  get questionYes () {
    const { getQuestionOptionScoreZero, alloyWheel } = this

    return getQuestionOptionScoreZero(alloyWheel, 1)?.find(item => item.name === 'Si')
  }

  get questionAlloyYes () {
    const { getQuestionOptionScoreZero, alloyWheel } = this

    return getQuestionOptionScoreZero(alloyWheel, 2)?.find(item => item.name === 'Si')
  }

  get questionAlloyNo () {
    const { alloyWheel, getQuestionOption } = this

    if (this.formData.alloyWheel.original.answer === getQuestionOption(alloyWheel, 1).id) {
      return getQuestionOption(alloyWheel, 2, false, 'No')
    }

    if (this.formData.alloyWheel.original.answer === getQuestionOption(alloyWheel, 1, true, 'No').id) {
      return getQuestionOption(alloyWheel, 2, true, 'No aplica')
    }

    return { id: null }
  }

  get capScrewOption () {
    const { capScrew, getQuestionOption } = this

    if (this.formData.capScrew.original.answer === getQuestionOption(capScrew, 1).id) {
      return getQuestionOption(capScrew, 2, false, 'No')
    }

    if (this.formData.capScrew.original.answer === getQuestionOption(capScrew, 1, true, 'No').id) {
      return getQuestionOption(capScrew, 2, true, 'No aplica')
    }

    return { id: null }
  }

  get wheelCoverOption () {
    const { wheelCovers, getQuestionOption } = this

    if (this.formData.wheelCovers.original.answer === getQuestionOption(wheelCovers, 1).id) {
      return getQuestionOption(wheelCovers, 2, false, 'No')
    }

    if (this.formData.wheelCovers.original.answer === getQuestionOption(wheelCovers, 1, true, 'No').id) {
      return getQuestionOption(wheelCovers, 2, true, 'No aplica')
    }

    return { id: null }
  }

  get isNotAlloy () {
    const { formData: { alloyWheel } } = this

    const notAlloyPresent = (alloyWheel.status.answer && alloyWheel.status.answer !== this.questionAlloyYes?.id)

    if (notAlloyPresent) {
      this.initStep = 2
    } else {
      this.initStep = 1
    }
    return notAlloyPresent
  }

  @Watch('formData.alloyWheel.original.answer', { immediate: true, deep: true })
  onAlloyChange (val) {
    this.initValues(val, this.alloyWheel, 'alloyWheel')

    const { getQuestionOption, alloyWheel, wheelCovers } = this
    if (!this.loading) {
      this.formData.alloyWheel.status.answer = null
      this.formData.alloyWheel.cost = 0
    }

    if (val === getQuestionOption(alloyWheel, 1, true, 'Si').id) {
      this.formData.wheelCovers.original.answer = getQuestionOption(wheelCovers, 1, true, 'No').id
    }
  }

  @Watch('formData.wheelCovers.original.answer', { immediate: true, deep: true })
  onWheelCoversChange (val) {
    this.initValues(val, this.wheelCovers, 'wheelCovers')

    if (!this.loading) {
      this.formData.wheelCovers.status.answer = null
      this.formData.capScrew.cost = 0
    }
  }

  @Watch('formData.capScrew.original.answer', { immediate: true, deep: true })
  onCapScrewChange (val) {
    this.initValues(val, this.capScrew, 'capScrew')
    if (!this.loading) {
      this.formData.capScrew.status.answer = null
      this.formData.capScrew.cost = 0
    }
  }

  initValues (val, component, field) {
    if (val === this.getQuestionOption(component, 1, true, 'No')?.id) {
      this.formData[field].cost = 0
    }
  }

  getColor (value, itemsArray) {
    const item = sortArray(itemsArray, 'name', true, '%')[value]
    if (!item) return null

    const percentage = parseInt(item.name.split('%')[0], 10)

    return getProgressColor(percentage)
  }

  get isSpare () {
    const { formData: { spareTire }, getQuestionOption, spareTire: component, displayQualification } = this

    const isSpare = spareTire.present.answer === getQuestionOption(component, 2).id

    if (!isSpare && spareTire.present.answer !== null && !displayQualification) {
      this.initSpareFields()
    }

    return isSpare
  }

  @Watch('formData.spareTire.should.answer', { immediate: true, deep: true })
  onShouldChange (val) {
    if (!val) return
    const { displayQualification } = this
    if (val === this.getQuestionOption(this.spareTire, 1, true, 'No')?.id && !displayQualification) {
      this.initSpareFields(true)
    } else this.removeSpareOption = val === this.getQuestionOption(this.spareTire, 1, true, 'No')?.id
  }

  @Watch('formData.spareTire.present.answer', { immediate: true, deep: true })
  onSpareChange (val) {
    if (!val) return
    if (val === this.getQuestionOption(this.spareTire, 2, false, 'No')?.id) {
      this.initSpareFields(false)
    } else if (val === this.getQuestionOption(this.spareTire, 2)?.id) {
      this.formData.spareTire.cost = 0
    }
  }

  initSpareFields (initPresent = false) {
    if (initPresent) {
      this.formData.spareTire.present.answer = null
      this.formData.spareTire.cost = 0
    }
    this.formData.spareTire.original.answer = null
    this.formData.spareTire.status.answer = null
    this.formData.spareTire.status.provisional = null

    this.formData.spareWheel = {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      level: {
        answer: null,
      },
      cost: undefined,
      photo: null,
      detail: {
        brand: null,
        width: null,
        height: null,
        diameter: null,
      },
    }
  }

  get isSpareCost () {
    const { formData: { spareTire }, getQuestionOption, spareTire: component } = this
    return spareTire.present.answer === getQuestionOption(component, 2, false, 'No').id
  }

  get watchRightFrontWheel () {
    const { formData: { rightFrontWheel }, rightFrontWheel: component, getQuestionOption } = this

    return {
      new: component && rightFrontWheel.new.answer === getQuestionOption(component, 8, false, 'Si').id,
      cost: rightFrontWheel.cost,
      component: rightFrontWheel,
    }
  }

  get watchLeftFrontWheel () {
    const { formData: { leftFrontWheel }, leftFrontWheel: component, getQuestionOption } = this

    return {
      new: component && leftFrontWheel.new.answer === getQuestionOption(component, 8, false, 'Si').id,
      cost: leftFrontWheel.cost,
      component: leftFrontWheel,
    }
  }

  get watchRightRearWheel () {
    const { formData: { rightRearWheel }, rightRearWheel: component, getQuestionOption } = this

    return {
      new: component && rightRearWheel.new.answer === getQuestionOption(component, 8, false, 'Si').id,
      cost: rightRearWheel.cost,
      component: rightRearWheel,
    }
  }

  get watchLeftRearWheel () {
    const { formData: { leftRearWheel }, leftRearWheel: component, getQuestionOption } = this

    return {
      new: component && leftRearWheel.new.answer === getQuestionOption(component, 8, false, 'Si').id,
      cost: leftRearWheel.cost,
      component: leftRearWheel,
    }
  }

  setWheelCost (field, fieldSet) {
    if (this.wheelCost[field].new) return
    const { inspection } = this

    if (this.formData[fieldSet].cost) {
      this.wheelCost[field].cost = this.formData[fieldSet].cost
      this.formData[field].cost = this.formData[fieldSet].cost
      this.wheelCost[field].new = true
      this.wheelCost[fieldSet].new = true

      insertIntoLocalStorage('inspection', inspection.id, this.wheelCost)
    }
  }

  @Watch('wheelCost', { immediate: true, deep: true })
  @Debounce(1000)
  onWheelCostChange (val) {
    if (!this.inspection.status.isActive) return
    if (val.rightFrontWheel.new && val.rightFrontWheel.cost) {
      this.formData.rightFrontWheel.cost = val.rightFrontWheel.cost
      this.formData.rightFrontWheel.new.answer = this.getQuestionOption(this.rightFrontWheel, 8, false, 'Si').id
    }
    if (val.leftFrontWheel.new && val.leftFrontWheel.cost) {
      this.formData.leftFrontWheel.cost = val.leftFrontWheel.cost
      this.formData.leftFrontWheel.new.answer = this.getQuestionOption(this.leftFrontWheel, 8, false, 'Si').id
    }
    if (val.rightRearWheel.new && val.rightRearWheel.cost) {
      this.formData.rightRearWheel.cost = val.rightRearWheel.cost
      this.formData.rightRearWheel.new.answer = this.getQuestionOption(this.rightRearWheel, 8, false, 'Si').id
    }
    if (val.leftRearWheel.new && val.leftRearWheel.cost) {
      this.formData.leftRearWheel.cost = val.leftRearWheel.cost
      this.formData.leftRearWheel.new.answer = this.getQuestionOption(this.leftRearWheel, 8, false, 'Si').id
    }
  }

  get isCategoryDone () {
    const { isMetadata, inspection, categoryName } = this
    return isMetadata && !inspection.metadata.findCategoryByName(categoryName).done && !inspection.metadata.findCategoryByName(categoryName)?.score
  }

  get watchCostRightFrontWheel () {
    const { formData: { rightFrontWheel }, rightFrontWheel: component } = this

    return {
      cost: rightFrontWheel.cost,
      component,
    }
  }

  get watchCostLeftFrontWheel () {
    const { formData: { leftFrontWheel }, leftFrontWheel: component } = this

    return {
      cost: leftFrontWheel.cost,
      component,
    }
  }

  get watchCostRightRearWheel () {
    const { formData: { rightRearWheel }, rightRearWheel: component } = this

    return {
      cost: rightRearWheel.cost,
      component,
    }
  }

  get watchCostLeftRearWheel () {
    const { formData: { leftRearWheel }, leftRearWheel: component } = this

    return {
      cost: leftRearWheel.cost,
      component,
    }
  }

  get wheelsDetails () {
    const { formData: { rightFrontWheel, leftFrontWheel, rightRearWheel, leftRearWheel }, getDescriptionWheel } = this

    return {
      rightFrontWheel: getDescriptionWheel(rightFrontWheel.detail) + ' ' + rightFrontWheel.detail.brand,
      leftFrontWheel: getDescriptionWheel(leftFrontWheel.detail) + ' ' + leftFrontWheel.detail.brand,
      rightRearWheel: getDescriptionWheel(rightRearWheel.detail) + ' ' + rightRearWheel.detail.brand,
      leftRearWheel: getDescriptionWheel(leftRearWheel.detail) + ' ' + leftRearWheel.detail.brand,
    }
  }

  @Watch('wheelsDetails', { immediate: true, deep: true })
  @Debounce(250)
  onWheelChange (val) {
    if (!this.inspection.status.isActive) return
    if (!val.rightFrontWheel.includes('null') && !val.leftFrontWheel.includes('null') && val.rightFrontWheel !== val.leftFrontWheel) {
      this.alertWheels.front = 'Los neumáticos delanteros no coinciden considere comprar un par nuevo'
      this.formData.rightFrontWheel.new.answer = this.getQuestionOption(this.rightFrontWheel, 8, false, 'Si').id
      this.formData.leftFrontWheel.new.answer = this.getQuestionOption(this.leftFrontWheel, 8, false, 'Si').id
    } else {
      this.alertWheels.front = ''
      this.formData.rightFrontWheel.new.answer = this.getQuestionOption(this.rightFrontWheel, 8, true, 'No').id
      this.formData.leftFrontWheel.new.answer = this.getQuestionOption(this.leftFrontWheel, 8, true, 'No').id
    }

    if (!val.rightRearWheel.includes('null') && !val.leftRearWheel.includes('null') && val.rightRearWheel !== val.leftRearWheel) {
      this.alertWheels.rear = 'Los neumáticos traseros no coinciden considere comprar un par nuevo'
      this.formData.rightRearWheel.new.answer = this.getQuestionOption(this.rightRearWheel, 8, false, 'Si').id
      this.formData.leftRearWheel.new.answer = this.getQuestionOption(this.leftRearWheel, 8, false, 'Si').id
    } else {
      this.alertWheels.rear = ''
      this.formData.rightRearWheel.new.answer = this.getQuestionOption(this.rightRearWheel, 8, true, 'No').id
      this.formData.leftRearWheel.new.answer = this.getQuestionOption(this.leftRearWheel, 8, true, 'No').id
    }
  }

  @Watch('watchCostRightFrontWheel', { immediate: true, deep: true })
  onCostRightFrontWheel (val) {
    if (!val.cost && val.component && this.inspection.status.isActive) {
      this.formData.rightFrontWheel.new.answer = this.getQuestionOption(val.component, 8, true, 'No').id
    }
  }

  @Watch('watchCostLeftFrontWheel', { immediate: true, deep: true })
  onCostLeftFrontWheel (val) {
    if (!val.cost && val.component && this.inspection.status.isActive) {
      this.formData.leftFrontWheel.new.answer = this.getQuestionOption(val.component, 8, true, 'No').id
    }
  }

  @Watch('watchCostRightRearWheel', { immediate: true, deep: true })
  onCostRightRearWheel (val) {
    if (!val.cost && val.component && this.inspection.status.isActive) {
      this.formData.rightRearWheel.new.answer = this.getQuestionOption(val.component, 8, true, 'No').id
    }
  }

  @Watch('watchCostLeftRearWheel', { immediate: true, deep: true })
  onCostLeftRearWheel (val) {
    if (!val.cost && val.component && this.inspection.status.isActive) {
      this.formData.leftRearWheel.new.answer = this.getQuestionOption(val.component, 8, true, 'No').id
    }
  }

  @Watch('tireBrand', { immediate: true, deep: true })
  async onBrandChange (val) {
    this.tireBrands.all = val?.values.map(v => v.value) || []
    this.tireBrands.all.push('Otra')
  }

  onRightFrontWheelBrandChange (val) {
    if (!val) return
    this.tireBrands.rightFrontWheel = this.tireBrands.all.filter(item => item.toLowerCase().includes(val.toLowerCase()))
  }

  onLeftFrontWheelBrandChange (val) {
    if (!val) return

    this.tireBrands.leftFrontWheel = this.tireBrands.all.filter(item => item.toLowerCase().includes(val.toLowerCase()))
  }

  onRightRearWheelBrandChange (val) {
    if (!val) return
    this.tireBrands.rightRearWheel = this.tireBrands.all.filter(item => item.toLowerCase().includes(val.toLowerCase()))
  }

  onLeftRearWheelBrandChange (val) {
    if (!val) return

    this.tireBrands.leftRearWheel = this.tireBrands.all.filter(item => item.toLowerCase().includes(val.toLowerCase()))
  }

  get displayRating () {
    const { categoryName, inspection } = this

    const score = inspection?.metadata?.findCategoryByName(categoryName)?.score

    return score && score >= 0
  }
  }
