
  import { Component, Watch } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { fixPrice, stringifySafe } from '@/utils/general'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import IdentifierPanel from '@/components/forms/inspection/panels/IdentifierPanel.vue'
  import { plainToInstance } from 'class-transformer'
  import { Inspection } from '@/entities/purchase'
  import InspectionQualification from '@/components/forms/inspection/inspectionQualification.vue'
  import InspectionNotQualified from '@/components/forms/inspection/inspectionNotQualified.vue'
  import MaintenancePanel from '@/components/forms/inspection/panels/MaintenancePanel.vue'
  import DocumentationPanel from '@/components/forms/inspection/panels/DocumentationPanel.vue'
  import BodyWorkPanel from '@/components/forms/inspection/panels/BodyWorkPanel.vue'
  import RearviewMirrorPanel from '@/components/forms/inspection/panels/RearviewMirrorPanel.vue'
  import TireAndWheelPanel from '@/components/forms/inspection/panels/TireAndWheelPanel.vue'
  import GeneralLoading from '@/components/core/GeneralLoading.vue'
  import GLoading from '@/components/core/GLoading.vue'
  import OpticPanel from '@/components/forms/inspection/panels/OpticPanel.vue'
  import InsidePanel from '@/components/forms/inspection/panels/InsidePanel.vue'
  import MechanicalPanel from '@/components/forms/inspection/panels/MechanicalPanel.vue'
  import ElectricPanel from '@/components/forms/inspection/panels/ElectricPanel.vue'
  import EquipmentPanel from '@/components/forms/inspection/panels/EquipmentPanel.vue'
  import ComfortEquipmentPanel from '@/components/forms/inspection/panels/ComfortEquipmentPanel.vue'
  import { Debounce } from '@/utils/decorators'
  import { mapActions, mapGetters } from 'vuex'
  import { ComponentCategory, Generation } from '@/entities/vehicle'
  import SecurityEquipmentPanel from '@/components/forms/inspection/panels/SecurityEquipmentPanel.vue'
  import ExteriorEquipmentPanel from '@/components/forms/inspection/panels/ExteriorEquipmentPanel.vue'
  import PhotosPanel from '@/components/forms/inspection/panels/PhotosPanel.vue'
  import _ from 'lodash'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import { getFromLocalStorage, insertIntoLocalStorage } from '@/utils/data'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import ButtonCard from '@/components/forms/inspection/cards/ButtonCard.vue'

@Component({
  components: {
    ButtonCard,
    GRadioButton,
    GAlert,
    PhotosPanel,
    ExteriorEquipmentPanel,
    SecurityEquipmentPanel,
    ComfortEquipmentPanel,
    EquipmentPanel,
    ElectricPanel,
    MechanicalPanel,
    InsidePanel,
    OpticPanel,
    GLoading,
    GeneralLoading,
    TireAndWheelPanel,
    RearviewMirrorPanel,
    BodyWorkPanel,
    DocumentationPanel,
    MaintenancePanel,
    InspectionNotQualified,
    InspectionQualification,
    IdentifierPanel,
    GFiles,
    LinkedAuto,
    BaseForm,
  },
  methods: {
    ...mapActions('resources/form', ['getCategoryScore', 'findLeadActivity']),
    fixPrice,
  },
  computed: {
    ...mapGetters('resources', ['categories']),
  },
})
  export default class InspectionView extends GForm {
  declare $refs: {
    form: HTMLFormElement,
    basePanel: DocumentationPanel
  };

  getCategoryScore!: ({ categoryName, idInspection }: { categoryName: string, idInspection: number }) => Promise<number>

  attributes = null
  fuel = null
  transmission = null
  traction = null
  radioButtonMessage = ''
  vehicleStatus = null
  totalSteps = 15
  categories!: Array<ComponentCategory>
  isCategoryDone = false
  rating = 0
  loading = true
  getInfoData = false
  isAlertDisplayed = false
  overCategory = null
  equipmentInTireAndWheel = null
  overAlert = {
    open: false,
    title: '',
  }

  isOver = false
  formVehicle = {
    brand: null,
    model: null,
    version: null,
    fuel: null,
    transmission: null,
    traction: null,
    year: null,
  }

  formData = {
    categoryName: null,
    category: null,
    score: null,
    total: 0,
  }

  categoryScore = {
    identifier: null,
    documentation: null,
    maintenance: null,
    bodywork: null,
    spotlightOptic: null,
    rearviewMirror: null,
    tireAndWheel: null,
    inside: null,
    electric: null,
    mechanical: null,
    equipment: null,
    comfortEquipment: null,
    securityEquipment: null,
    externalEquipment: null,
    photos: null,
  }

  categoryTarget = {
    Identificación: 'identifier',
    Documentación: 'documentation',
    Mantenimiento: 'maintenance',
    Carrocería: 'bodywork',
    Lunetas: 'rearviewMirror',
    'Focos y ópticos': 'spotlightOptic',
    Ruedas: 'tireAndWheel',
    Interior: 'inside',
    Eléctrica: 'electric',
    Mecánica: 'mechanical',
    Equipamiento: 'equipment',
    'Equipamiento comfort': 'comfortEquipment',
    'Equipamiento seguridad': 'securityEquipment',
    'Equipamiento exterior': 'externalEquipment',
    Fotos: 'photos',
  }

  categoryFixed = {
    Identificación: 'identifier',
    Documentación: 'documentation',
    Mantenimiento: 'maintenance',
    Carrocería: 'bodywork',
    Lunetas: 'rearviewMirror',
    'Focos y ópticos': 'spotlightOptic',
    Ruedas: 'tireAndWheel',
    Interior: 'inside',
    Eléctrica: 'electric',
    Mecánica: 'mechanical',
    'Equipamiento básico': 'equipment',
    'Equipamiento comfort': 'comfortEquipment',
    'Equipamiento seguridad': 'securityEquipment',
    'Equipamiento exterior': 'externalEquipment',
    Fotos: 'photos',
  }

  category = {
    identifier: false,
    documentation: false,
    maintenance: false,
    bodywork: false,
    spotlightOptic: false,
    rearviewMirror: false,
    tireAndWheel: false,
    inside: false,
    electric: false,
    mechanical: false,
    equipment: false,
    comfortEquipment: false,
    securityEquipment: false,
    externalEquipment: false,
    photos: false,
  }

  form = {
    identifier: {
      panels: 0,
    },
    documentation: {
      panels: 0,
    },
    maintenance: {
      panels: 0,
    },
    bodywork: {
      panels: 0,
    },
    spotlightOptic: {
      panels: 0,
    },
    rearviewMirror: {
      panels: 0,
    },
    tireAndWheel: {
      panels: 0,
    },
    inside: {
      panels: 0,
    },
    electric: {
      panels: 0,
    },
    mechanical: {
      panels: 0,
    },
    equipment: {
      panels: 0,
    },
    comfortEquipment: {
      panels: 0,
    },
    securityEquipment: {
      panels: 0,
    },
    externalEquipment: {
      panels: 0,
    },
    photos: {
      panels: 0,
    },
  }

  cards = {
    identifier: false,
    documentation: false,
    maintenance: false,
    bodywork: false,
    spotlightOptic: false,
    rearviewMirror: false,
    tireAndWheel: false,
    inside: false,
    electric: false,
    mechanical: false,
    equipment: false,
    comfortEquipment: false,
    securityEquipment: false,
    externalEquipment: false,
    photos: false,
  }

  componentCategories = {
    identifier: null,
    documentation: null,
    maintenance: null,
    bodywork: null,
    spotlightOptic: null,
    rearviewMirror: null,
    tireAndWheel: null,
    inside: null,
    electric: null,
    mechanical: null,
    equipment: null,
  }

  openAlert = false
  openVehicle = false
  generation = null
  maxMileageDifference = null
  minPercentageDifference = null
  categoryQualificationConfig = null
  sendQualification = false
  done = false
  finishCategories = false
  panels = -1
  title = 'Inspección'
  formChanged = false
  metadata = null
  idProcess = null
  idProcessInspection = null
  photoProperties = {
    properties: {
      accept: null,
      multiple: false,
      fileTypeId: null,
      name: '',
    },
  }

  metadataCategories = []
  inspection: Inspection = plainToInstance(Inspection, {})

  findLeadActivity!: (payload: number) => Promise<any>

  async mounted () {
    await this.setMetadata()
    const { uid, title, metadata } = this

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection' } },
    })

    this.categoryQualificationConfig = process?.[0]?.config?.categoryConfig
    this.maxMileageDifference = process?.[0]?.config?.maxMileageDifference
    this.minPercentageDifference = process?.[0]?.config?.minPercentageDifference

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadata, title, Boolean(uid))
    }

    if (!isNaN(uid)) {
      await this.getInfo(uid)
    }

    await this.setCategories()

    const val = getFromLocalStorage('equipmentInTireAndWheelInspection', this.inspection.id)
    if (val) {
      this.equipmentInTireAndWheel = val
    }
  }

  async setCategories () {
    const identifier = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Identificación' } },
    })

    const bodywork = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Carrocería' } },
    })

    const rearviewMirror = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Lunetas' } },
    })

    const optic = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Focos y ópticos' } },
    })

    const tireAndWheel = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Ruedas' } },
    })

    const maintenance = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Mantenimiento' } },
    })

    const inside = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Interior' } },
    })

    const mechanical = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Mecánica' } },
    })

    const documentation = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Documentación' } },
    })

    const electric = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Eléctrica' } },
    })

    const equipment = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Equipamiento' } },
    })

    this.componentCategories = {
      identifier: identifier?.[0],
      bodywork: bodywork?.[0],
      rearviewMirror: rearviewMirror?.[0],
      spotlightOptic: optic?.[0],
      tireAndWheel: tireAndWheel?.[0],
      mechanical: mechanical?.[0],
      maintenance: maintenance?.[0],
      inside: inside?.[0],
      documentation: documentation?.[0],
      electric: electric?.[0],
      equipment: equipment?.[0],
    }
  }

  get change () {
    const { form, formData, loading, formChanged, inspection, cards } = this

    return stringifySafe([form, formData, loading, formChanged, inspection, cards])
  }

  get canQualify () {
    const { inspection } = this

    return inspection?.metadata?.canQualify
  }

  get someCardOpen () {
    const { cards } = this
    return Object.values(cards).some(card => card)
  }

  async setMetadata () {
    const { metadata } = this.getForm('Inspection', 'inspection')
    this.metadata = metadata

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection_inspected_component' } },
    })

    this.idProcess = process?.[0]?.id

    const processInspection = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection' } },
    })

    this.idProcessInspection = processInspection?.[0]?.id

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'inspection_inspected_component' } } },
    })

    if (fileInfo?.length) {
      this.photoProperties.properties = {
        accept: fileInfo[0].fileType.mimes,
        multiple: fileInfo[0].multiple,
        fileTypeId: fileInfo[0].fileType.id,
        name: fileInfo[0].name,
      }
    }
  }

  @Debounce()
  async getInfo (id) {
    const { isInspectionOver } = this
    if (isInspectionOver) return
    this.loadingForm = true
    this.inspection = await this.fetchData({ query: { name: 'fetch', model: 'Inspection', params: { id } }, force: true })
    this.getInfoData = true
  }

  async categoryDone () {
    const { inspection } = this
    this.isCategoryDone = true
    this.closeForms()
    await this.getInfo(inspection.id)
  }

  setPanels (keys, val, total) {
    const { finishCategories } = this

    if (this.category[keys[val]]) {
      this.closePanels()
      return
    }

    if (val === total || finishCategories) {
      this.finishCategories = true
      this.closePanels()
      this.category[keys[val]] = true
      return
    }

    if (val > 0 && this.category[keys[val - 1]]) {
      if (keys[val]) {
        this.category[keys[val]] = true
      }
      this.form[keys[val]].panels = -1
      if (keys[val + 1] && this.form[keys[val + 1]]) {
        this.form[keys[val + 1]].panels = 0
      }
    } else if (val === 0) {
      this.category[keys[val]] = true
      this.form[keys[val]].panels = -1
      if (keys[val + 1] && this.form[keys[val + 1]]) {
        this.form[keys[val + 1]].panels = 0
      }
    }
  }

  async updateProgress (inspection, categories) {
    const { totalSteps, isOver } = this

    if (!categories?.length || isOver) return

    const total = categories?.filter(category => category?.done || category?.score !== null)?.length

    if (isNaN(total)) return

    const progress = (total / totalSteps) * 100
    if (isNaN(progress)) return

    if (progress && inspection?.metadata?.progress === progress) {
      return
    }

    if (!inspection?.metadata?.progress) {
      await this.pushData({
        model: 'Inspection',
        fields: { id: inspection.id, metadata: { categories, progress } },
      })
    } else {
      inspection.metadata.categories = categories
      await this.pushData({
        model: 'Inspection',
        fields: { id: inspection.id, metadata: { ...inspection.metadata } },
      })
    }
  }

  inspectionDone () {
    this.done = true
  }

  async send () {
    this.loadingForm = true
    this.sendQualification = true
  }

  enableButton (val) {
    this.disableSend = !val
  }

  get someActive () {
    const { cards } = this

    return Object.values(cards).some(card => card)
  }

  get isInspectionOver () {
    const { inspection } = this

    if (!inspection?.id) return false
    if (inspection?.status?.isClosed) this.panels = -1
    return Boolean(inspection?.status?.isClosed)
  }

  get isInspectionFinished () {
    const { inspection } = this
    if (!inspection?.id) return false
    return Boolean(inspection?.status?.isFinished) || Boolean(inspection?.status?.isInSupervision)
  }

  get isInspectionInProgress () {
    const { inspection } = this
    if (!inspection?.id) return false
    return Boolean(inspection?.status?.isActive)
  }

  get isInspectionClosed () {
    const { isInspectionOver, isInspectionFinished } = this

    return isInspectionOver || isInspectionFinished
  }

  @Watch('isInspectionClosed', { immediate: true })
  async onInspectionClose (val) {
    if (val) {
      const { inspection } = this

      const idActivity = await this.findLeadActivity(inspection.deal.lead.id)

      if (idActivity) {
        const close = await this.fetchData({
          query: { name: 'find', model: 'ProcessStatus' },
          filter: { _and: [{ process: { table_name: { _eq: 'lead_activity' } } }, { status: { name: { _eq: 'closed' } } }] },
          force: true,
        })
        await this.pushData({
          model: 'LeadActivity',
          fields: { id: idActivity, activity_result: true, id_process_status: close?.[0]?.id },
        })
      }
    }
  }

  @Watch('getInfoData', { immediate: true, deep: true })
  @Debounce(1000)
  async onCategoriesUpdate (flag) {
    const val = this.inspection
    if (!val?.id || !flag) {
      this.loadingForm = false
      return
    }

    const { category, formData, categoryQualificationConfig, isOver } = this

    if (isOver) return

    await this.setCategoriesScore(val)

    const keys = Object.keys(category)

    if (formData?.category) {
      const { categoryName, category, score, total } = formData

      const newScore = score || this.getScore(categoryQualificationConfig, category, val, categoryName, this.categoryScore)
      const result = await this.updateCategory(categoryName, newScore, val, total)

      this.formData.category = null
      this.formData.score = null
      this.formData.categoryName = null
      this.formData.total = 0

      if (result) {
        this.inspection.metadata.categories = this.mergeCategories(val, result)
      }
    }

    await this.handleCategories(categoryQualificationConfig, keys, this.categoryScore)

    if (!this.metadataCategories?.length && val.metadata?.categories?.length) {
      this.metadataCategories = val.metadata.categories
    }

    await this.viewInspectionStatus(val?.metadata)
  }

  async setCategoriesScore (val) {
    this.categoryScore = {
      identifier: await this.getCategoryScore({
        idInspection: val.id,
        categoryName: 'Identificación',
      }),
      documentation: await this.getCategoryScore({
        idInspection: val.id,
        categoryName: 'Documentación',
      }),
      maintenance: await this.getCategoryScore({
        idInspection: val.id,
        categoryName: 'Mantenimiento',
      }),
      bodywork: await this.getCategoryScore({
        idInspection: val.id,
        categoryName: 'Carrocería',
      }),
      spotlightOptic: await this.getCategoryScore({
        idInspection: val.id,
        categoryName: 'Focos y ópticos',
      }),
      rearviewMirror: await this.getCategoryScore({
        idInspection: val.id,
        categoryName: 'Lunetas',
      }),
      tireAndWheel: await this.getCategoryScore({
        idInspection: val.id,
        categoryName: 'Ruedas',
      }),
      inside: await this.getCategoryScore({
        idInspection: val.id,
        categoryName: 'Interior',
      }),
      electric: await this.getCategoryScore({
        idInspection: val.id,
        categoryName: 'Eléctrica',
      }),
      mechanical: await this.getCategoryScore({
        idInspection: val.id,
        categoryName: 'Mecánica',
      }),
      equipment: null,
      comfortEquipment: null,
      securityEquipment: null,
      externalEquipment: null,
      photos: null,
    }
  }

  getOverAlertTitle (category) {
    const { categoryTarget } = this

    if (categoryTarget[category] === 'identifier') {
      return `Con la respuesta dada en ${category} finaliza la inspección y no podrá avanzar`
    }

    return `Con la respuesta dada en la ${category} puedes finalizar la inspección, si le das cancelar puedes continuar y calificar en cualquier momento`
  }

  async handleCategories (config, keys, scores) {
    let cont = -1
    const { categoryTarget } = this

    const categories = _.cloneDeep(this.inspection.metadata?.categories)?.sort((a, b) => a.order - b.order) || []
    let someOver = false

    categories?.forEach((category, index) => {
      if (config[category.name]?.over && scores[categoryTarget[category.name]] && scores[categoryTarget[category.name]] >= config[category.name]?.over) {
        someOver = true
      }

      if (someOver && !this.overCategory) {
        this.overCategory = category.name
        this.overAlert.open = true
        this.overAlert.title = this.getOverAlertTitle(category.name)
        this.isCategoryDone = false
      }

      if (category?.done && !someOver && this.overCategory && category.name === this.overCategory) {
        this.overCategory = null
        this.isCategoryDone = false
        this.overAlert.open = false
      }

      if (category?.done && (categoryTarget?.[this.overCategory] !== 'identifier')) {
        cont = index + 1
        this.category[keys[index]] = true
      }
    })

    if (!someOver) {
      this.isCategoryDone = false
      this.overAlert.open = false
      this.overCategory = null
    }

    await this.updateProgress(this.inspection, categories)

    this.handlePanels(cont, keys)
  }

  async viewInspectionStatus (metadata) {
    const { generation } = this
    if (!generation) {
      this.generation = await this.getAutoGeneration(this.inspection.auto)
      this.transmission = this.generation.transmission
      this.traction = this.generation.traction
      this.fuel = this.generation.fuel
    }

    if (metadata?.done) {
      this.inspectionDone()
    }
    this.openAlertCondition()

    this.setBackup({ inspection: this.inspection })
    this.loadingForm = false
    this.getInfoData = false
  }

  openAlertCondition () {
    const { isAlertDisplayed, inspection } = this
    if ((!inspection?.date) && !isAlertDisplayed) {
      this.openAlert = true
    }
  }

  handlePanels (cont, keys) {
    if (cont !== -1 && keys[cont]) {
      if (this.form[keys[cont]].panels !== 0) {
        this.form[keys[cont]].panels = 0
        this.changePanel(keys[cont])
      }
    } else if (!keys[cont] && cont !== -1) {
      this.closePanels()
    }

    this.loading = false
  }

  mergeCategories (val: any, result: any) {
    const valCategories = val.metadata.categories || []
    const resultCategories = result.metadata.categories || []

    const newCategoryMap: Record<string, any> = {};

    [...valCategories, ...resultCategories].forEach((category: any) => {
      newCategoryMap[category.name] = category
    })

    if (this.metadataCategories?.length) {
      const existingCategoryMap: Record<string, any> = {}

      this.metadataCategories.forEach((category: any) => {
        existingCategoryMap[category.name] = category
      })

      for (const [name, category] of Object.entries(newCategoryMap)) {
        existingCategoryMap[name] = category
      }

      this.metadataCategories = Object.values(existingCategoryMap)
    } else {
      this.metadataCategories = Object.values(newCategoryMap)
    }

    return this.metadataCategories
  }

  getScore (categoryQualificationConfig, category, inspection, name, scores) {
    if (!category?.id || !categoryQualificationConfig) return undefined

    const { categoryTarget } = this
    let score

    const equipment = {
      'Equipamiento básico': 'basic_equipment',
      'Equipamiento comfort': 'comfort_equipment',
      'Equipamiento seguridad': 'security_equipment',
      'Equipamiento exterior': 'aesthetic_equipment',
    }

    if (name === 'Equipamiento exterior') {
      score = inspection.getCategoryScore(category.id, equipment[name]) - this.equipmentInTireAndWheel
    } else if (equipment?.[name]) {
      score = inspection.getCategoryScore(category.id, equipment[name])
    } else {
      score = scores[categoryTarget[name]]
    }

    let configValue: number | undefined

    Object.entries(categoryQualificationConfig?.[name]?.scores).forEach(([key, value]) => {
      const config = value as Record<string, any>
      if (score >= config.min && (config.max === null || score <= config.max)) {
        configValue = config.value
      }
    })

    return configValue
  }

  changePanel (panel) {
    for (const key in this.form) {
      if (key !== panel) {
        this.form[key].panels = -1
      }
    }
  }

  closePanels () {
    for (const key in this.form) {
      this.form[key].panels = -1
    }
  }

  async confirmInitInspection () {
    this.radioButtonMessage = ''
    if (this.vehicleStatus === null) {
      this.radioButtonMessage = 'Debe seleccionar una opción'
      return
    }
    await this.pushData({
      model: 'Inspection',
      fields: {
        id: this.inspection.id,
        metadata: { ...this.inspection.metadata, vehicleStatus: this.vehicleStatus },
      },
    })
    if (this.vehicleStatus) {
      this.$router.back()
    } else {
      this.openAlert = false
      this.vehicleStatus = null
      this.openVehicle = true
    }
  }

  cancelInitInspection () {
    this.close()
  }

  validateAttributes (formVehicle) {
    const keys = Object.keys(formVehicle)

    const attributes = ['fuel', 'transmission', 'traction']
    let isSomeNull = false
    keys.forEach(key => {
      isSomeNull = (formVehicle[key] === null && !attributes.includes(key)) || (formVehicle[key] === null && attributes.includes(key) && this[key])
    })

    return isSomeNull
  }

  async confirmVehicle () {
    const { formVehicle, isSomeResponseFalse } = this

    if (this.validateAttributes(formVehicle)) {
      this.radioButtonMessage = 'Debe seleccionar una opción'
      return
    }

    if (!isSomeResponseFalse) {
      this.openVehicle = false
    } else {
      this.openVehicle = false
      const closed = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'inspection' } } }, { status: { name: { _eq: 'closed' } } }] },
      })

      const filter = {
        _and: [
          { type: { name: { _eq: 'not_match' } } },
          { status: { process: { table_name: { _eq: 'inspection' } } } },
        ],
      }

      const reason = await this.fetchData({
        query: { name: 'find', model: 'ClosingReason' },
        filter,
      })

      const notMatch = {
        brand: formVehicle.brand,
        model: formVehicle.model,
        version: formVehicle.version,
        year: formVehicle.year,
        fuel: formVehicle.fuel,
        transmission: formVehicle.transmission,
        traction: formVehicle.traction,
      }

      for (const key in notMatch) {
        if (notMatch[key]) {
          delete notMatch[key]
        }
      }
      await this.pushData({
        model: 'Inspection',
        fields: {
          id: this.inspection.id,
          id_inspector: this.idEmployee,
          id_process_status: closed?.[0]?.id,
          id_closing_reason: reason?.[0]?.id,
          metadata: { ...this.inspection.metadata, notMatch },
        },
      })
      const notMatchStatus = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'auto' } } }, { status: { name: { _eq: 'not_match' } } }] },
      })

      await this.pushData({
        model: 'Auto',
        fields: {
          id: this.inspection.auto.id,
          id_process_status: notMatchStatus?.[0]?.id,
        },
      })

      const dealLost = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'deal' } } }, { status: { name: { _eq: 'lost' } } }] },
      })

      const dealFilter = {
        _and: [
          { type: { name: { _eq: 'not_match' } } },
          { status: { process: { table_name: { _eq: 'deal' } } } },
        ],
      }

      const dealReason = await this.fetchData({
        query: { name: 'find', model: 'ClosingReason' },
        filter: dealFilter,
      })

      await this.updateDealProcessStatusAndClosingReason({
        id: this.inspection.deal.id,
        idProcessStatus: dealLost?.[0]?.id,
        idClosingReason: dealReason?.[0]?.id,
      })
      this.$router.back()
    }
    this.isAlertDisplayed = true
  }

  async confirmOver () {
    this.overAlert.open = false
    const idProcessStatus = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'inspection' } } }, { status: { name: { _eq: 'finished' } } }] },
    })

    const pos = this.inspection.metadata?.categories?.findIndex(category => category.name === this.overCategory)
    this.inspection.metadata.categories[pos].validated = false
    this.inspection.metadata.categories[pos].progress = 0

    await this.pushData({
      model: 'Inspection',
      fields: {
        id: this.inspection.id,
        id_process_status: idProcessStatus?.[0]?.id,
        metadata: { ...this.inspection.metadata, overCategory: this.overCategory },
      },
    })
    this.isOver = true
    await this.close()
  }

  cancelOver () {
    this.overAlert.open = false

    const category = this.categoryTarget[this.overCategory]
    this.allCategoriesToFalse(category)
    if (category !== 'identifier') {
      this.form.identifier.panels = 0
      this.closeIdentifier()
    }
  }

  @Debounce(500)
  closeIdentifier () {
    this.form.identifier.panels = -1
  }

  findKeyInCategoryTarget (categoryKey) {
    for (const key in this.categoryTarget) {
      if (this.categoryTarget[key] === categoryKey) {
        return key
      }
    }
    return null
  }

  allCategoriesToFalse (category) {
    let categoryFound = false
    const categ = this.inspection.metadata.findCategoryByName(this.overCategory)

    for (const key in this.category) {
      if (categoryFound) {
        this.category[key] = false
      } else {
        const target = this.findKeyInCategoryTarget(key)
        this.category[key] = key !== 'identifier' && this.inspection.metadata.findCategoryByName(target)?.done
        if (key === category && !categ.done) {
          categoryFound = true
          this.category[key] = false
        }
      }
    }
  }

  @Watch('equipmentInTireAndWheel', { immediate: true })
  onEquipmentInTireAndWheelChange (val) {
    if (val) {
      insertIntoLocalStorage('equipmentInTireAndWheelInspection', this.inspection.id, val)
    }
  }

  @Watch('form', { immediate: true, deep: true })
  @Debounce(3000)
  onFormPanelChange () {
    this.formChanged = !this.formChanged
  }

  async getAutoGeneration (auto) {
    if (!auto?.generation?.id) return this.findAlternativesAttributes(auto)

    const generation = await this.fetchData({
      query: { name: 'fetch', params: { id: auto.generation.id }, model: 'Generation' },
    })

    if (generation?.attributes?.length) {
      return generation
    }

    return this.findAlternativesAttributes(auto)
  }

  async findAlternativesAttributes (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    let getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id)

    let generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.id === auto.generation?.id)

    if (generation?.attributes?.length) {
      return generation
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        id_version_year: { _eq: auto.version.id },
      },
    })

    if (attributes?.length) {
      if (!generation) {
        generation = plainToInstance(Generation, {})
      }
      generation.attributes = attributes
      return generation
    }

    if (!auto?.generation?.sku) {
      return
    }

    let cont = 1
    while (cont <= 3 && !generation?.attributes?.length) {
      getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id - cont)

      generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.sku === auto.generation?.sku)

      if (generation?.attributes?.length) {
        return generation
      } else {
        cont++
      }
    }

    return generation
  }

  async getGeneration (version, year) {
    return this.fetchData({
      query: { name: 'find', model: 'Generation' },
      filter: { version_year: { id_version: { _eq: version }, id_year: { _eq: year } } },
    })
  }

  @Watch('category', { immediate: true, deep: true })
  onCategoryChange (val) {
    if (val[this.categoryTarget[this.overCategory]]) {
      this.overAlert.open = true
    }
  }

  openCard (val) {
    const category = this.categoryFixed[val.category]
    for (const key in this.cards) {
      if (key !== category) {
        this.cards[key] = false
      }
    }

    this.cards[category] = val.open
  }

  closeForms () {
    const keys = Object.keys(this.cards)

    keys.forEach(key => {
      this.cards[key] = false
    })

    this.getInfo(this.inspection.id)
  }

  async updateCategory (categoryName, score, inspection, total = 0, order = null) {
    if (!score || !categoryName) return

    const fetchedInspection = await this.fetchData({
      query: { name: 'fetchLite', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const pos = fetchedInspection.metadata?.categories?.findIndex(category => category.name === categoryName)
    const alternativeOrder = fetchedInspection.metadata?.categories?.length

    if (!fetchedInspection?.metadata?.categories) {
      await this.pushData({
        model: 'Inspection',
        fields: {
          id: inspection.id,
          metadata: {
            categories: [
              {
                order,
                name: categoryName,
                score,
                progress: 100,
                inspectorCost: total,
                inspectorValidated: true,
              }],
          },
        },
      })
    } else if (pos === -1) {
      inspection.metadata.categories.push({
        order: order || alternativeOrder,
        name: categoryName,
        score,
        progress: 100,
        inspectorCost: total,
        inspectorValidated: true,
      })
      await this.pushData({
        model: 'Inspection',
        fields: { id: inspection.id, metadata: { ...inspection.metadata } },
      })
    } else {
      inspection.metadata.categories[pos].score = score
      inspection.metadata.categories[pos].inspectorCost = total
      inspection.metadata.categories[pos].inspectorValidated = true
      inspection.metadata.categories[pos].order = pos + 1 || alternativeOrder
      await this.pushData({
        model: 'Inspection',
        fields: { id: inspection.id, metadata: { ...inspection.metadata } },
      })
    }

    return inspection
  }

  get isSomeResponseFalse () {
    const { formVehicle } = this

    return Object.values(formVehicle).some(value => value === false)
  }

  get overCategoryMessage () {
    const { overCategory } = this

    return `Inspección no califica en ${overCategory}`
  }

  get displayOverQualification () {
    const { overCategory } = this

    return overCategory !== 'Identificación' && overCategory
  }

  get displayOtherCards () {
    const { inspection } = this

    const score = inspection?.metadata?.findCategoryByName('Identificación')?.score

    return Boolean(score && score >= 0)
  }
  }
