
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import { Debounce } from '@/utils/decorators'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { isArray } from 'class-validator'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  components: { GCostField, RatingCell, GFiles, BasePanel, GFormSlot },
})
  export default class RearviewMirrorPanel extends BaseStepper {
  componentKeys = ['windshield', 'leftFrontWindow', 'leftRearWindow', 'rearWindow', 'rightRearWindow', 'rightFrontWindow']
  validation = false
  fieldsKeys = ['original', 'status']
  loadingSend = false
  cost = {
    windshield: false,
    leftFrontWindow: false,
    leftRearWindow: false,
    rearWindow: false,
    rightRearWindow: false,
    rightFrontWindow: false,
  }

  formData = {
    windshield: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    leftFrontWindow: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    leftRearWindow: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    rearWindow: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    rightRearWindow: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    rightFrontWindow: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
  }

  errorIdentifierMessage = {
    windshield: {
      original: '',
      provisional: '',
      status: '',
    },
    leftFrontWindow: {
      original: '',
      provisional: '',
      status: '',
    },
    leftRearWindow: {
      original: '',
      provisional: '',
      status: '',
    },
    rearWindow: {
      original: '',
      provisional: '',
      status: '',
    },
    rightRearWindow: {
      original: '',
      provisional: '',
      status: '',
    },
    rightFrontWindow: {
      original: '',
      provisional: '',
      status: '',
    },
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get statusItems () {
    const {
      windshield,
      leftFrontWindow,
      leftRearWindow,
      rearWindow,
      rightRearWindow,
      rightFrontWindow,
    } = this

    return {
      windshield: windshield.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      leftFrontWindow: leftFrontWindow.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      leftRearWindow: leftRearWindow.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      rearWindow: rearWindow.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      rightRearWindow: rightRearWindow.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      rightFrontWindow: rightFrontWindow.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
    }
  }

  get statusAnswer () {
    const {
      windshield,
      leftFrontWindow,
      leftRearWindow,
      rearWindow,
      rightRearWindow,
      rightFrontWindow,

    } = this
    return {
      windshield: windshield.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      leftFrontWindow: leftFrontWindow.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      leftRearWindow: leftRearWindow.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      rearWindow: rearWindow.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      rightRearWindow: rightRearWindow.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      rightFrontWindow: rightFrontWindow.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
    }
  }

  get rightFrontWindow () {
    return this.findComponentBySlug(this.components, 'right_front_window')
  }

  get rightRearWindow () {
    return this.findComponentBySlug(this.components, 'right_rear_window')
  }

  get rearWindow () {
    return this.findComponentBySlug(this.components, 'rear_window')
  }

  get leftRearWindow () {
    return this.findComponentBySlug(this.components, 'left_rear_window')
  }

  get windshield () {
    return this.findComponentBySlug(this.components, 'windshield')
  }

  get leftFrontWindow () {
    return this.findComponentBySlug(this.components, 'left_front_window')
  }

  setErrorMessage (key, field) {
    if (field === 'status' && this.formData[key][field].provisional === null) {
      this.errorIdentifierMessage[key].provisional = 'Este campo es requerido'
    }

    this.errorIdentifierMessage[key][field] = 'Este campo es requerido'
  }

  validateFields (data, key) {
    const fields = ['original', 'status']
    let isValid = false

    const handleValidation = field => {
      const isArr = isArray(data[field]?.answer)
      const isNum = !isNaN(data[field]?.answer)

      if ((isNum && !data[field]?.answer) || (isArr && !data[field]?.answer.length)) {
        this.setErrorMessage(key, field)
        return true
      }
      this.errorIdentifierMessage[key][field] = ''
      return false
    }

    for (const field of fields) {
      const isProvisional = 'provisional' in data[field]
      const validNotProvisional = isProvisional === false && handleValidation(field)
      const validProvisional = (isProvisional === true && !data[field]?.provisional) && handleValidation(field)

      if (data[field]?.provisional) {
        this.validation = true
        this.formData[key].status.answer = []
      }

      if (validNotProvisional || validProvisional) {
        isValid = true
        break
      } else {
        this.errorIdentifierMessage[key][field] = ''
      }
    }

    return isValid
  }

  async send (component, data, field = null) {
    if (this.validateFields(data, field)) {
      return
    }
    this.loadingSend = true
    const { step, photoProperties, inspection, cost, displayQualification } = this

    await this.sendComponentPieceAttach(component, this.formData[field], photoProperties, inspection.id)

    if (!cost[field] && data?.photo?.length) {
      await this.deleteFiles(data.photo)
    }
    this.step = step + 1

    if (displayQualification) {
      const { categoryName, category, categoryQualificationConfig } = this
      this.$emit('input', { categoryName, category, categoryQualificationConfig })
    }
    this.loadingSend = false
  }

  get allComponents () {
    const {
      windshield,
      leftFrontWindow,
      leftRearWindow,
      rearWindow,
      rightRearWindow,
      rightFrontWindow,
      selfPanel,
    } = this

    return {
      windshield,
      leftFrontWindow,
      leftRearWindow,
      rearWindow,
      rightRearWindow,
      rightFrontWindow,
      selfPanel,
    }
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    if (!val.selfPanel) return
    const { idProcess, inspection, componentKeys, autoClose, disabled, displayQualification } = this

    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification && this.step !== componentKeys.length - 1) {
          this.step = step
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })
        this.formData[key].original.answer = matchingInspectedComponent.findInspectionParameterByOrder(1).assessment.id

        if (!matchingInspectedComponent.findInspectionParameterByOrder(2)?.assessment?.score) {
          this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(2)?.assessment?.id
          this.formData[key].status.answer = null
        } else {
          this.formData[key].status.provisional = false
          this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(2).map(item => item.assessment.id)
        }
        this.formData[key].cost = matchingInspectedComponent.cost
      }
    }

    for (const [index, key] of componentKeys.entries()) {
      await handleComponentChange(val[key], index + 1, key)
    }
  }

  @Watch('step', { immediate: true })
  async onStepChange (val) {
    const {
      componentKeys,
      selfPanel,
      displayRating,
      inspection,
      categoryName,
      progress,
      category,
      categoryQualificationConfig,
    } = this
    const total = this.getTotalCost(this.formData)
    if (!displayRating) {
      await this.updateCategoryProgress(inspection, categoryName, progress, 6)
    } else if (displayRating && selfPanel) {
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
    }
    if (val > componentKeys.length && selfPanel) {
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
      this.$emit('inspection-category-done')
    }
  }

  get progress () {
    const { step, componentKeys } = this

    if (step > 1) {
      return ((step - 1) / componentKeys.length) * 100
    }

    return 0
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  @Watch('formData', { immediate: true, deep: true })
  onFormDataChange (val) {
    const { fieldsKeys, componentKeys, validation, selfPanel } = this
    if (!selfPanel) {
      return
    }

    if (validation) {
      this.validation = false
      return
    }
    componentKeys.forEach(key => {
      const data = val[key]
      this.cost[key] = this.isAnyFieldValid(fieldsKeys, data, this[key])

      this.initiateErrorMessage(key)

      if (!this.cost[key] && this.formData[key].cost) {
        this.formData[key].cost = 0
      }
    })
  }

  initiateErrorMessage (key) {
    this.errorIdentifierMessage[key].original = ''
    this.errorIdentifierMessage[key].status = ''
    this.errorIdentifierMessage[key].provisional = ''
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  get displayRating () {
    const { categoryName, inspection } = this

    const score = inspection?.metadata?.findCategoryByName(categoryName)?.score

    return score && score >= 0
  }
  }
