
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import { Debounce } from '@/utils/decorators'
  import KeyContent from '@/components/forms/inspection/steppers/content/equipment/basic/KeyContent.vue'
  import CarExhaustContent from '@/components/forms/inspection/steppers/content/CarExhaustContent.vue'
  import { isArray } from 'class-validator'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  components: {
    GCostField,
    GRadioButton,
    CarExhaustContent,
    KeyContent,
    RatingCell,
    GFiles,
    GFormSlot,
    BasePanel,
  },
})
  export default class EquipmentPanel extends BaseStepper {
  componentKeys = ['steeringWheel', 'radio', 'airConditioning', 'centralLock', 'glassLifters', 'electricMirror']
  loadingSend = false
  keyCost = 0
  fieldsKeys = ['status']
  validation = false
  cost = {
    steeringWheel: false,
    radio: false,
    airConditioning: false,
    centralLock: false,
    glassLifters: false,
    electricMirror: false,
  }

  formData = {
    steeringWheel: {
      status: {
        answer: null,
        provisional: false,
      },
      cost: undefined,
      photo: null,
    },
    radio: {
      status: {
        answer: null,
        provisional: false,
      },
      cost: undefined,
      photo: null,
    },
    airConditioning: {
      status: {
        answer: null,
        provisional: false,
      },
      cost: undefined,
      photo: null,
    },
    centralLock: {
      status: {
        answer: null,
        provisional: false,
      },
      cost: undefined,
      photo: null,
    },
    glassLifters: {
      status: {
        answer: null,
        provisional: false,
      },
      cost: undefined,
      photo: null,
    },
    electricMirror: {
      status: {
        answer: null,
        provisional: false,
      },
      cost: undefined,
      photo: null,
    },
  }

  errorIdentifierMessage = {
    steeringWheel: {
      provisional: '',
      status: '',
    },
    radio: {
      provisional: '',
      status: '',
    },
    airConditioning: {
      provisional: '',
      status: '',
    },
    centralLock: {
      provisional: '',
      status: '',
    },
    glassLifters: {
      provisional: '',
      status: '',
    },
    electricMirror: {
      provisional: '',
      status: '',
    },
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get basicEquipment () {
    return this.findComponentBySlug(this.components, 'basic_equipment')
  }

  get keyComponent () {
    return this.basicEquipment?.values.find(val => val.value === 'Llaves')
  }

  get allComponents () {
    return this.basicEquipment?.values.filter(val => val.value !== 'Llaves').sort((a, b) => a.inspectionComponents[0].order - b.inspectionComponents[0].order)
  }

  @Watch('step', { immediate: true })
  @Debounce(100)
  async onStepChange (val) {
    const {
      basicEquipment,
      selfPanel,
      categoryName,
      inspection,
      displayRating,
      progress,
      category,
      categoryQualificationConfig,
    } = this
    if (!selfPanel) return
    const total = this.getTotalCost(this.formData) + this.keyCost || 0

    if (!displayRating) {
      await this.updateCategoryProgress(inspection, categoryName, progress, 11)
    }

    if (displayRating && selfPanel) {
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
    }

    if (val > basicEquipment.values.length && selfPanel) {
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
      this.$emit('inspection-category-done')
    }
  }

  get progress () {
    const { step, basicEquipment } = this

    if (step > 1) {
      return ((step - 1) / basicEquipment?.values?.length) * 100
    }

    return 0
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  setErrorMessage (key, field) {
    if (field === 'status' && (this.formData[key][field].provisional === null || this.formData[key][field].provisional === undefined)) {
      this.errorIdentifierMessage[key].provisional = 'Este campo es requerido'
    } else if (field === 'status' && (this.formData[key][field].provisional !== null || this.formData[key][field].provisional !== undefined)) {
      this.errorIdentifierMessage[key].provisional = ''
    }

    this.errorIdentifierMessage[key][field] = 'Este campo es requerido'
  }

  validateFields (data, key) {
    const fields = ['status']
    let isValid = false

    const handleValidation = field => {
      const isArr = isArray(data[field]?.answer)
      const isNum = !isNaN(data[field]?.answer)

      if ((isNum && !data[field]?.answer) || (isArr && !data[field]?.answer.length)) {
        this.setErrorMessage(key, field)
        return true
      }
      this.errorIdentifierMessage[key][field] = ''
      return false
    }

    for (const field of fields) {
      const isProvisional = 'provisional' in data[field]
      const validNotProvisional = isProvisional === false && handleValidation(field)
      const validProvisional = (isProvisional === true && !data[field]?.provisional) && handleValidation(field)

      if (data[field]?.provisional) {
        this.validation = true
        this.formData[key].status.answer = []
      }

      if (validNotProvisional || validProvisional) {
        isValid = true
        break
      } else {
        this.errorIdentifierMessage[key][field] = ''
      }
    }

    return isValid
  }

  async send (component, data, field = null) {
    if (this.validateFields(data, field)) {
      return
    }
    this.loadingSend = true
    const { step, photoProperties, inspection, cost, displayQualification } = this

    await this.sendComponentOptics(component, this.formData[field], photoProperties, inspection.id)

    if (!cost[field] && data?.photo?.length) {
      await this.deleteFiles(data.photo)
    }
    this.step = step + 1
    const total = this.getTotalCost(this.formData) + this.keyCost || 0
    if (displayQualification) {
      const { categoryName, category, categoryQualificationConfig } = this
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
    }

    this.$emit('update:inspection')
    this.loadingSend = false
  }

  get allData () {
    const { allComponents, selfPanel } = this

    return {
      allComponents, selfPanel,
    }
  }

  @Watch('allData', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    if (!val.selfPanel) return
    const { idProcess, inspection, componentKeys, autoClose, disabled, displayQualification } = this

    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification && this.step !== componentKeys.length - 1) {
          this.step = step
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        if (!matchingInspectedComponent.findInspectionParameterByOrder(1)?.assessment?.score) {
          this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(1)?.assessment?.id
          this.formData[key].status.answer = null
        } else {
          this.formData[key].status.provisional = false
          this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(1).map(item => item.assessment.id)
        }

        this.formData[key].cost = matchingInspectedComponent.cost
        if (matchingInspectedComponent.cost) {
          this.cost[key] = true
        }
      }

      if (((this.formData.electricMirror.status.provisional === false && this.formData.electricMirror.status.answer) || (this.formData.electricMirror.status.provisional)) && !displayQualification) {
        this.step = this.step + 1
      }
    }

    for (const [index, key] of componentKeys.entries()) {
      await handleComponentChange(val.allComponents[index], index + 2, key)
    }
  }

  @Watch('formData', { immediate: true, deep: true })
  onFormDataChange (val) {
    const { fieldsKeys, componentKeys, validation, selfPanel, allComponents } = this
    if (!selfPanel || !allComponents?.length) {
      return
    }

    if (validation) {
      this.validation = false
      return
    }
    componentKeys.forEach((key, index) => {
      const data = val[key]
      this.cost[key] = this.isAnyFieldValid(fieldsKeys, data, allComponents[index])

      this.initiateErrorMessage(key)
      if (!this.cost[key] && this.formData[key].cost) {
        this.formData[key].cost = 0
      }
    })
  }

  initiateErrorMessage (key) {
    this.errorIdentifierMessage[key].status = ''
    this.errorIdentifierMessage[key].provisional = ''
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  get displayRating () {
    const { categoryName, inspection } = this

    const score = inspection?.metadata?.findCategoryByName(categoryName)?.score

    return score && score >= 0
  }
  }
